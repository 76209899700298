import React, { useEffect, useState } from "react";
import {
  Input,
  Row,
  Col,
  Form,
  Button,
  Typography,
  Tabs,
  Image,
  Alert,Select
} from "antd";
import {
  createPayee,
  payeeAccountObj,
  savePayee,
  confirmTransaction,getRelationDetails
} from "../api";
import AddressDocumnet from "../document.upload";
import PayeeBankDetails from "./bankdetails.component";
import { validateFeild} from "../../../utils/custom.validator";
import ConnectStateProps from "../../../utils/state.connect";
import Loader from "../../shared/loader";
import alertIcon from "../../../assets/images/pending.png";
import {isErrorDispaly} from "../../../api/apiCalls";

const { Paragraph, Text, Title } = Typography;
const {TextArea } = Input;
const {Option}=Select;
const SomeoneComponent = (props) => {
  const [addressOptions, setAddressOptions] = useState({
    addressType: "individuals",
    transferType: props.currency === "EUR" ? "sepa" : "swift",
    domesticType: "sepa"
  });
  const [bankdetails, setBankdetails] = useState(null);
  const [createPayeeObj, setCreatePayeeObj] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [mainLoader, setMailLoader] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [showDeclartion, setShowDeclartion] = useState(false);
  const [intialObj, setIntialObj] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isTabChange, setIsTabChange] = useState(false);
  const [isSelectedId, setIsSelectedId] = useState(null);
  const [relationData,setRelationData]=useState([]);
  const [selectedRelation,setSelectedRelation]=useState(null);
  const [rasonDocuments,setReasonDocuments]=useState(null);
  const [form] = Form.useForm();
  const useDivRef = React.useRef(null);

  useEffect(() => {
    getpayeeCreate();
    getRelationData();
  }, []);
  const getpayeeCreate = async () => {
    setMailLoader(true);
    const createPayeeData = await createPayee(
      props.selectedAddress?.id || "",
      addressOptions.addressType
    );
    if (createPayeeData.ok) {
      let edit = false;
      setCreatePayeeObj(createPayeeData.data);
      if (props.selectedAddress?.id) {
        setIntialObj({
          ...createPayeeData.data,
          payeeAccountModels: createPayeeData.data.payeeAccountModels[0]
        });
        setDocuments(createPayeeData.data.payeeAccountModels[0].documents);
        setAddressOptions({
          ...addressOptions,
          domesticType: createPayeeData.data.transferType
        });
        edit = true;
        props?.onEdit(edit);
        setEdit(true);
        setIsSelectedId(createPayeeData?.data?.id);
      }
      setMailLoader(false);
    } else {
      setMailLoader(false);
      setErrorMessage(isErrorDispaly(createPayeeData))
    }
  };
  const onSubmit = async (values) => {
    if (Object.hasOwn(values?.payeeAccountModels, 'iban')) {
        setErrorMessage(null);
        if ((!bankdetails || Object.keys(bankdetails).length == 0)) {
            useDivRef.current.scrollIntoView()
            setErrorMessage("Please click validate button before saving");
            return;
        }
    }
    let obj = { ...createPayeeObj, ...values };
    obj.payeeAccountModels = [payeeAccountObj()];
    obj.payeeAccountModels[0] = { ...obj.payeeAccountModels[0], ...bankdetails, ...values.payeeAccountModels };
    obj.others =values?.relation==="Others"? values?.others:null;
    obj.payeeAccountModels[0].currencyType = "Fiat";
    obj.payeeAccountModels[0].docrepoitory = documents;
    obj.payeeAccountModels[0].walletCode = props.currency;
    if (props.selectedAddress?.id) { obj.payeeAccountModels[0].id = createPayeeObj.payeeAccountModels[0].id; }
    obj['customerId'] = props.userProfile.id;
    obj['transferType'] = (props.currency === "USD"||addressOptions.domesticType==="swifttransfer") ? addressOptions.domesticType : 'sepa';
    obj['addressType'] = addressOptions.addressType;
    obj.createdBy = props?.userProfile?.userName;
    if(edit){
        obj.id = isSelectedId ? isSelectedId : createPayeeObj.payeeAccountModels[0]?.payeeId;
    }
    setBtnLoading(true)
    let payeesave = await savePayee(obj)
    if (payeesave.ok) {
        if (props.type !== "manual") {
            // const confirmRes = await confirmTransaction({ payeeId: payeesave.data.id, amount: props.onTheGoObj.amount, reasonOfTransfer: obj.reasonOfTransfer,AccountId:props.selectedAccountId,
            //   transferOthers:obj?.transferOthers,
            //   docRepositories:rasonDocuments,
            //   info:JSON.stringify(this.props?.trackAuditLogData),
            //  })
            // if (confirmRes.ok) {
                setBtnLoading(false);
                props.onContinue(payeesave.data);
                props.reasonAddress(rasonDocuments);
                useDivRef?.current?.scrollIntoView(0,0);
            // } else {
            //     setBtnLoading(false);
            //     setErrorMessage(isErrorDispaly(confirmRes));
            //     useDivRef.current.scrollIntoView(0,0);
            // }
        } else {
            props.headingUpdate(true);
            setShowDeclartion(true);
            props.isHideTabs(false)
        }
    } else {
        setBtnLoading(false);
        setErrorMessage(isErrorDispaly(payeesave));
        useDivRef.current.scrollIntoView(0,0);
    }
}
 
  const getIbandata = (data) => {
    setErrorMessage(null);
    if (data && !data?.bankName) {
      useDivRef.current.scrollIntoView();
      return setErrorMessage("No bank details are available for this IBAN number");
    } else if (data) {
      setIsTabChange(false);
      setBankdetails(data);
    } else {
      setBankdetails(data);
    }
  };
  const getRelationData=async()=>{
    let res = await getRelationDetails()
    if(res.ok){
        setRelationData(res.data)
        setErrorMessage(null)
    }else{
        setErrorMessage(isErrorDispaly(res))
    }
}
  const handleRelation = (e) => {
    setSelectedRelation(e)
    form.current.setFieldsValue({ others: null })
  }
  const handleTabChange=(activekey) => {
      setAddressOptions({ ...addressOptions, domesticType: activekey, tabType: activekey,transferType:activekey });
      form.current.resetFields();
      setSelectedRelation(null);
      setDocuments(null);
      setReasonDocuments(null);
      setErrorMessage(null);
      setBankdetails(null)
      setBtnLoading(false);
      edit ? setIsTabChange(false) : setIsTabChange(true);
  }
  
  return (
    <React.Fragment>
      {mainLoader && <Loader />}
      {!mainLoader && (
        <>
          <div ref={useDivRef}></div>
          {showDeclartion && (
            <div className="text-center">
              <Image width={80} preview={false} src={alertIcon} className="confirm-icons"/>
              <Title level={2} className="success-title">
                Declaration form sent successfully to your email
              </Title>
              <Text className="successsubtext">{`Declaration form has been sent to ${props.userProfile?.email}. 
                   Please sign using link received in email to whitelist your address. `}</Text>
              <Text className="successsubtext">{`Please note that your withdrawal will only be processed once your whitelisted address has been approved`}</Text>
              <div className="my-25">
                <Button
                  onClick={() =>
                    props.onContinue({ close: true, isCrypto: false })
                  }
                  type="primary"
                  className="mt-36 pop-btn text-textDark"
                >
                  BACK
                </Button>
              </div>
            </div>
          )}

          {!showDeclartion && (
            <>
              {props.currency === "USD" && (
                <>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={24} xl={24} xxl={24} className="">
                    <Tabs activeKey={addressOptions.domesticType} style={{ color: '#fff' }} className="cust-tabs-fait fiat-tabscls" 
                    onChange={(activekey) => {
                                setAddressOptions({ ...addressOptions, domesticType: activekey, tabType: activekey });
                                form.current.resetFields();
                                setDocuments(null);
                                setErrorMessage(null);
                                setBankdetails(null)
                                edit ? setIsTabChange(false) : setIsTabChange(true);
                            }}>
                        <Tabs.TabPane
                          tab="Domestic USD Transfer"
                          className="text-white"
                          key={"domestic"}
                          disabled={edit}
                        ></Tabs.TabPane>
                        <Tabs.TabPane
                          tab="International USD Swift"
                          className="text-white"
                          key={"international"}
                          disabled={edit}
                        ></Tabs.TabPane>
                        <Tabs.TabPane
                          tab="International USD IBAN"
                          className="text-white text-captz"
                          key={"internationalIBAN"}
                          disabled={edit}
                        ></Tabs.TabPane>
                      </Tabs>
                    </Col>
                  </Row>
                </>
              )}
              {props.currency == "EUR" && (
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={24} xl={24} xxl={24} className="">
                    <Tabs activeKey={addressOptions.domesticType} style={{ color: '#fff' }} className="cust-tabs-fait fiat-tabscls" 
                    onChange={(activeKey)=>handleTabChange(activeKey)}>
                        <Tabs.TabPane
                          tab="SEPA Transfer"
                          className="text-white"
                          key={"sepa"}
                          disabled={edit}
                        ></Tabs.TabPane>
                         <Tabs.TabPane
                          tab="SWIFT Transfer"
                          className="text-white"
                          key={"swifttransfer"}
                          disabled={edit}
                        ></Tabs.TabPane>
                      </Tabs>
                    </Col>
                  </Row>
              )}

              {errorMessage && (
                <Alert
                  type="error"
                  showIcon
                  closable={false}
                  description={errorMessage}
                />
              )}
              <Form
                ref={form}
                onFinish={onSubmit}
                initialValues={intialObj}
                scrollToFirstError
              >
                <Row gutter={[16, 16]} className={"pb-16"}>
                  <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                    <Form.Item
                      className="custom-forminput custom-label mb-0"
                      name="favouriteName"
                      required
                      rules={[
                        {
                          whitespace: true,
                          message: "Is required"
                        },
                        {
                          validator: (_, val) => {
                            return validateFeild({
                              val: val,
                              required: true,
                              maxLength: 50,
                              validateContentRule: true,
                              lableName:"save whitelist name as"
                            });
                          }
                        }
                      ]}
                      label={"Save Whitelist Name As"}
                    >
                      <Input
                        maxLength={100}
                        className="cust-input"
                        placeholder={"Save Whitelist Name As"}
                      />
                    </Form.Item>
                  </Col>
                   
                </Row>
                <Paragraph className="adbook-head" > Recipient's Details </Paragraph>
                <>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item
                        className="custom-forminput custom-label mb-0"
                        name="firstName"
                        required
                        rules={[
                          {
                            whitespace: true,
                            message: "Is required"
                          },
                          {
                            validator: (_, val) => {
                              return validateFeild({
                                val: val,
                                required: true,
                                maxLength: 50,
                                validateContentRule: true,
                                lableName: "first name"
                              });
                            }
                          }
                        ]}
                        label={"First Name"}
                      >
                        <Input
                          maxLength={100}
                          className="cust-input"
                          placeholder={"First Name"}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item
                        className="custom-forminput custom-label mb-0"
                        name="lastName"
                        required
                        rules={[
                          {
                            whitespace: true,
                            message: "Is required"
                          },
                          {
                            validator: (_, val) => {
                              return validateFeild({
                                val: val,
                                required: true,
                                maxLength: 50,
                                validateContentRule: true,
                                lableName: "last name"
                              });
                            }
                          }
                        ]}
                        label={"Last Name"}
                      >
                        <Input
                          maxLength={100}
                          className="cust-input"
                          placeholder={"Last Name"}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item
                                className="custom-forminput custom-label"
                                name="relation"
                                label={"Relationship To Beneficiary"}
                                required
                                    rules={[
                                      {
                                        whitespace: true,
                                        message: "Is required"
                                      },
                                      {
                                        validator: (_, val) => {
                                          return validateFeild({
                                            val: val,
                                            required: true,
                                            maxLength: 50,
                                            validateContentRule: true,
                                          });
                                        }
                                      }
                                    ]}
                            >
                                <Select
                                    className="cust-input"
                                    maxLength={100}
                                    placeholder={"Relationship To Beneficiary"}
                                    optionFilterProp="children"
                                    onChange={(e)=>handleRelation(e)}
                                >
                                    {relationData?.map((item, idx) => (
                                    <Option key={idx} value={item.name}>
                                        {item.name}
                                    </Option>
                                    ))}
                                </Select>

                            </Form.Item>
                        </Col>
                        {selectedRelation=="Others" && <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item
                            className=" mb-8 px-4 text-white-50 custom-forminput custom-label pt-8 sc-error"
                            name="others"
                            required
                            rules={[
                              {
                                whitespace: true,
                                message: "Is required"
                              },
                              {
                                validator: (_, val) => {
                                  return validateFeild({
                                    val: val,
                                    required: true,
                                    maxLength: 50,
                                    validateContentRule: true,
                                  });
                                }
                              }
                            ]}
                            >
                            <Input
                                className="cust-input"
                                maxLength={100}
                                placeholder="Please specify:"
                            />
                            </Form.Item>
                      </Col>}
                    <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                        <Paragraph className="sub-abovesearch code-lbl upload-btn-mt">Please upload supporting documents to prove your relationship with the beneficiary. E.g. Contracts, Agreements</Paragraph>
                        <AddressDocumnet documents={documents || null} editDocument={edit} onDocumentsChange={(docs) => {
                            setDocuments(docs)
                            }} refreshData={addressOptions?.domesticType} type={"payee"}/>
                            </Col>
                    <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item
                        className="custom-forminput custom-label mb-0"
                        name="line1"
                        required
                        rules={[
                          {
                            whitespace: true,
                            message: "Is required"
                          },
                          {
                            validator: (_, val) => {
                              return validateFeild({
                                val: val,
                                required: true,
                                maxLength: 50,
                                validateContentRule: true,
                              });
                            }
                          }
                        ]}
                        label={"Address Line 1"}
                      >
                        <TextArea
                          placeholder={"Address Line 1"}
                          className="cust-input cust-text-area address-book-cust"
                          autoSize={{ minRows: 1, maxRows: 2 }}
                          maxLength={1000}
                        ></TextArea>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item
                        className="custom-forminput custom-label mb-0"
                        name="line2"
                        rules={[
                          {
                            validator: (_, val) => {
                              return validateFeild({
                                val: val,
                                maxLength: 50,
                                validateContentRule: true,
                              });
                            }
                          }
                        ]}
                        label={"Address Line 2"}
                      >
                        <TextArea
                          placeholder={"Address Line 2"}
                          className="cust-input cust-text-area address-book-cust"
                          autoSize={{ minRows: 1, maxRows: 2 }}
                          maxLength={1000}
                        ></TextArea>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                      <Form.Item
                        className="custom-forminput custom-label mb-0"
                        name="line3"
                        rules={[
                          {
                            validator: (_, val) => {
                              return validateFeild({
                                val: val,
                                maxLength: 250,
                                validateContentRule: true,
                                lableName: "address line 3"
                              });
                            }
                          }
                        ]}
                        label={"Address Line 3"}
                      >
                        <TextArea
                          placeholder={"Address Line 3"}
                          className="cust-input cust-text-area address-book-cust"
                          autoSize={{ minRows: 1, maxRows: 2 }}
                          maxLength={1000}
                        ></TextArea>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
                <Paragraph
                  className="adbook-head"
                >
                  Bank Details
                </Paragraph>
                {((props.selectedAddress?.id && createPayeeObj)||!props.selectedAddress?.id ) &&
                 <PayeeBankDetails GoType={props.ontheGoType} selectedAddress={props.selectedAddress} createPayeeObj={createPayeeObj} form={form} domesticType={addressOptions?.domesticType} transferType={addressOptions?.transferType} getIbandata={(data)=>getIbandata(data)} isAddTabCange={isTabChange} bankdetails={bankdetails}/>}
                <Paragraph className="mb-0 sub-abovesearch code-lbl upload-btn-mt ">
                Please Upload Supporting Documents To Justify Your Transfer Request. E.G. Invoice, Agreements
                </Paragraph>
                <AddressDocumnet documents={documents || null} editDocument={edit} onDocumentsChange={(docs) => {
                        setReasonDocuments(docs)
                    }} refreshData = {addressOptions?.domesticType} type={"reasonPayee"}/>
                <div className="text-right mt-12">
                  <Button
                    block
                    htmlType="submit"
                    size="large"
                    className="pop-btn px-36"
                    loading={btnLoading}
                  >
                    {props.type === "manual" && "Save"}
                    {props.type !== "manual" && "Continue"}
                  </Button>
                </div>
              </Form>
            </>
          )}
        </>
      )}
    </React.Fragment>
  );
};
export default ConnectStateProps(SomeoneComponent);

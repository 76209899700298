import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom';
import Home from '../components/home';
import CallbackPage from '../auth/callback.component';
import OnBoarding from './onboard.component';
import Dashboard from '../components/dashboard.component'
import Transfer from '../components/transfer.component'
import AccountCreation from '../components/createAccount.component'
import BusinessCreateAccount from '../components/createAccount.component/businessCreateAccount';
import AccountView from '../components/dashboard.component/accountView'
import Receive from '../components/recive.component/index';
import SuisseBaseDigitalWallet from '../components/suissebaseDigitalWallet';
import PastRecipientDetails from '../components/suissebaseDigitalWallet/summaryDetails';
import ProgressPage from '../components/createAccount.component/progressPage';
 import CommoingInternalTransfer from '../components/internalTransfer.component/commingInternalTransfer';
import NotKyc from "../components/shared/notKyc";
import TwoFactor from '../components/shared/two.factor'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AccessDenied from '../components/shared/permission/access.denied';
class RouteConfig extends Component {
    componentDidMount=()=>{
        if(!this.props.userProfileInfo?.twofactorVerified){
            this.props.history.push('/enableTwoFactor')
          }
     
    }
    redirecttoNotKyc=()=>{
        if(!window.location.pathname.includes('notKyc')){
          this.props.history.push('/notKyc')
        }
      }
    render() {
        if( this.props.userProfileInfo && !this.props.userProfileInfo?.isKYC){
            return <>{this.redirecttoNotKyc()}</>
          }else if(!this.props.userProfileInfo?.twofactorVerified && !window.location.pathname.includes('enableTwoFactor')){
            this.props.history.push('/enableTwoFactor')
          }
        return (
            <>
                <Switch>
                    {/* dashboard */}
                    <Route path="/dashboard/:action?/:currency?/:id?" component={Dashboard} />

                    {/* create account */}
                    <Route path='/businessCreateAccount/:currency' component={BusinessCreateAccount} />
                    <Route path="/progressPage/:currency?" component={ProgressPage}/>
                    <Route path='/createAccount/:currency' component={AccountCreation} />
                    <Route path='/enableTwoFactor' component={TwoFactor}/>


                    <Route path="/callback" component={CallbackPage} />
                    <Route path="/onboading" component={OnBoarding} />
                    <Route path='/transfer/:isPopup?' component={Transfer} />
                   
                    <Route path="/accountView/:id/:isReceivePopup?" component={AccountView} />
                    <Route path="/accountViewS/:id/:isReceivePopup?" component={ProgressPage} />
                  
                 
                    <Route path="/pastRecipientDetails" component={PastRecipientDetails} />
                 
                    <Route path="/" component={Dashboard} exact />
                    <Route path="/receive" component={Receive} />
                    <Route path="/digitalWallet" component={SuisseBaseDigitalWallet} />
                 
                    <Route path='/internaltransfer' component={CommoingInternalTransfer}/>
                    <Route path='/notKyc' component={NotKyc}/> 
                    <Route path='/accessdenied' component={AccessDenied} />

                </Switch>
            </>
        )
    }
}


const connectStateToProps = ({ userConfig }) => {
    return { userProfileInfo: userConfig.userProfileInfo };
  };
  const connectDispatchToProps = (dispath) => {
    return { dispath };
  };
  export default connect(
    connectStateToProps,
    connectDispatchToProps
  )(withRouter(RouteConfig));
  
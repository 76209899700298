import { apiMain, bankClient,ipRegistry} from "./clients"
import{ApiControllers} from './config';
import CryptoJS from "crypto-js";

 const getMember = () => {
  return apiMain.get(ApiControllers.customers + `App/Bank`);
}
 const getBank = () => {
   return bankClient.get(ApiControllers.bank + `IsAccountExist`);
}
const saveAccount = (obj) => {
  return bankClient.post(ApiControllers.bank + `SaveAccount`,(obj));
}
const getViewData = (accountId) => {
	return bankClient.get(
		ApiControllers.bank + `GetAccount/account/${accountId}`);
  }
  const getCountryLu = () => {
    return apiMain.get(ApiControllers.common + "ControlCodes?codeCategory=country");
  }
  const getCustomerData =()=>{
    return bankClient.get(
      ApiControllers.bank + `GetCustomerData`);
    }
    const getCustomerBankDetails = ()=>{
      return bankClient.get(ApiControllers.bank + `AccountDetailsForBank`);
    }

    const getCurrencywithBank=()=>{
      return apiMain.get(ApiControllers.withdraw + `Withdraw/CurrencyWithBank`)
  }
  const getCurrency=(currency)=>{
    return bankClient.get(ApiControllers.bank + `GetAccountBalance/${currency}`)
}
const getListOfCurrencies=()=>{
    return bankClient.get(ApiControllers.bank + `ListofCurrencies`)
}
const getMonthlyFee=(currency)=>{
  return bankClient.get(ApiControllers.bank + `AccountCreation/${currency}`)
}
const encryptValue = (msg, key) => {
	msg = typeof msg == "string" ? msg : JSON.stringify(msg);
	let salt = CryptoJS.lib.WordArray.random(128 / 8);

	let key1 = CryptoJS.PBKDF2(key, salt, {
		keySize: 256 / 32,
		iterations: 10,
	});

	let iv = CryptoJS.lib.WordArray.random(128 / 8);

	let encrypted = CryptoJS.AES.encrypt(msg, key1, {
		iv: iv,
		padding: CryptoJS.pad.Pkcs7,
		mode: CryptoJS.mode.CBC,
	});
	return salt.toString() + iv.toString() + encrypted.toString();
};
const sumsublivenessacesstoken = (userid, flow) => {
	return apiMain.get(
		"Sumsub/ExternalAccessToken?userId=" + userid + "&levelName=" + flow
	);
};

const getRecipientData=(payeeId,addresType)=>{
	return bankClient.get(
        ApiControllers.addressbook + `payee/Withdraw/Favorite/${payeeId}/${addresType}`
    );
}

const saveTransferData=(obj)=>{
	return bankClient.post(ApiControllers.addressbook+'Payee',obj)
}

const getIBANData = (ibanNumber) => {
	return bankClient.get(
		ApiControllers.addressbook + `GetIBANAccountDetails?ibanNumber=`+ibanNumber
	);
};
const getCountryStateLu = () => {
  return apiMain.get(ApiControllers.common + "ControlCodes?codeCategory=country");
}


const getStateLookup = (countryName) => {

	return bankClient.get(ApiControllers.addressbook + "States?countryName="+countryName);
  }
const getIpRegistery = () => {
	return ipRegistry.get("/?key="+process.env.REACT_APP_IPREGISTERY_KEY);
};
const getAccountDetails=()=>{
  return bankClient.get(ApiControllers.bank + `AccountDetailsForBank`)
}
const getIdTypesLU =()=>{
  return bankClient.get(ApiControllers.bank + `controlcodeslu/IdTypes`)
}
const getAddressTypeLU = ()=>{
  return bankClient.get(ApiControllers.bank + "controlcodeslu/AddressTypes")
}
const downloadConfirmation = (accountId,currency,status)=>{
  return bankClient.get(ApiControllers.bank + `accountconfirmationslip/${accountId}/${currency}/${status}`)
}
const previewData = (transactionId,type) =>{
  return bankClient.get(ApiControllers.bank + `transactiondetailspreview/${transactionId}/${type}`)
}

const previewDataDownload = (transactionId,type) =>{
  return bankClient.get(ApiControllers.bank + `transactiondetailsfile/${transactionId}/${type}`)
}
const isErrorDispaly = (objValue) => {
	if ((objValue.status >= 400 && objValue.status < 500) && objValue.status != 401) {
		return "Something went wrong please try again!";
	} else {
		if (objValue.data && typeof objValue.data === "string") {
			return objValue.data;
		} else if (objValue.data && objValue.data.title && typeof objValue.data.title) {
			return objValue.data.title;
		} else if (
			objValue.originalError &&
			typeof objValue.originalError.message === "string"
		) {
			return objValue.originalError.message;
		} else {
			return "Something went wrong please try again!";
		}
	}
};
const uploadErrorDisplay = (objValue)=>{
	if ((objValue.status >= 400 && objValue.status < 500) && objValue.status != 401) {
		return "Something went wrong please try again!";
	} else {
		if ( objValue.title && typeof objValue.title) {
			return objValue.title;
		}   else {
			return "Something went wrong please try again!";
		}
	}
}
const getFileURL = (docId) => {
    return apiMain.get(ApiControllers.common + `FilePreview/${docId}`);
  };
export {getMember,getIpRegistery, getBank, saveAccount,getViewData,getCountryLu,getCustomerData,getCustomerBankDetails,getCurrencywithBank,getCurrency,getListOfCurrencies,getMonthlyFee, encryptValue, sumsublivenessacesstoken, getRecipientData,
  saveTransferData,getIBANData,getCountryStateLu,getStateLookup,getAccountDetails,getIdTypesLU,getAddressTypeLU,downloadConfirmation,previewData,previewDataDownload,isErrorDispaly,uploadErrorDisplay,getFileURL}


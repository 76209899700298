import Typography from "antd/lib/typography";
import Form from "antd/lib/form";
import Button from "antd/lib/button";
import Alert from "antd/lib/alert";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter,useHistory } from "react-router-dom";
import { getMonthlyFee,isErrorDispaly } from "../../api/apiCalls";
import Loader from "../shared/loader";
import { getFeaturePermissionsByKey } from "../../components/shared/permission/permissionService";

const { Title } = Typography;

const FeePage = (props) => {
  const [form] = Form.useForm();
  const [accountsData, setAccountsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  let history = useHistory();



  useEffect(() => {
    getAccountFee(); 
    getFeaturePermissionsByKey('dashboard')
    loadPermissions()
  }, []);

  const loadPermissions = () => {
    if (props.dashboardPermissions) {
      let _permissions = {};
      for (let action of props.dashboardPermissions?.actions) {
        _permissions[action.permissionName] = action.values;
      }
      if (!_permissions?.createaccount) {
        history.push("/accessdenied");
      }
    }
  }

  const getAccountFee = async () => {
    setIsLoading(true);
    let response = await getMonthlyFee(props.match.params.currency);
    if (response.ok) {
      setError(null)
      setAccountsData(response.data);
      setIsLoading(false);
    } else {
      setError(isErrorDispaly(response));
      setIsLoading(false);
    }
  };
   const createAccount = (e) => { 
    if (props.userProfileInfo.isBusiness) {
      props.onChnagestep('createBusinessAccount')
    }else{
      props.onChnagestep('createPersonalAccount')
    }

  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
         
        <Form form={form} autoComplete="off">
          
          <div className="creat-bg cust-bg-width">
          
              {error && <Alert type="error" showIcon description={error} />}
             <div className="custom-createaccount alert-info-custom">
             <div className="cut-crd-space">
                <Title className="db-titles">Before you proceed</Title>
              </div>
                  <div className="pay-list" style={{ alignItems: 'baseline' }}>
                  <Title className="summary-liststyle">Account Creation </Title>
                  <Title className="summarybal">
                    {accountsData.accoutCreationFee}
                    </Title>
                  </div>
                <div className="pay-list" style={{ alignItems: 'baseline' }}>
                <Title className="summary-liststyle">Monthly Fees </Title>
                  <Title className="summarybal">{accountsData.monthlyFee}</Title>
                  </div>
                  <div className="text-right mt-24 p-0 create-account">
              <Form.Item>
                {" "}
                <Button
                  type="submit"
                  htmlType="submit"
                  block
                  className="pop-btn mb-0"
                  onClick={() => createAccount(props.match.params.currency)}
                >
                  Confirm and continue
                </Button>
              </Form.Item>
            </div>
            </div>
            
          </div>
          
        </Form>
      )}
    </>
  );
};

const connectStateToProps = ({ userConfig, customerDetails,menuItems }) => {
  return { userProfileInfo: userConfig.userProfileInfo, 
    customerDetails:customerDetails.data,
    dashboardPermissions: menuItems?.featurePermissions?.dashboard,

  };
};
const connectDispatchToProps = (dispath) => {
  return { dispath };
};
export default connect(
  connectStateToProps,
  connectDispatchToProps
)(withRouter(FeePage));

import { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import AppLayout from "./layout";
import { store } from "./store";
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  const {isLoading, isAuthenticated,loginWithRedirect} = useAuth0();

  useEffect(()=>{
    localStorage.setItem("__url", window.location.pathname);
      if(!isAuthenticated && !isLoading){
            loginWithRedirect();
      }

  },[])
  return (
    <Provider store={store}>
      <BrowserRouter>
        {isLoading ? <div className="loader">Loading....</div> : <AppLayout /> }
      </BrowserRouter>
    </Provider>
  );
}

export default App;

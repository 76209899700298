import React, {  useState } from "react";
import { Form, Row, Col, Radio } from 'antd';
import { useForm } from "antd/lib/form/Form";
import ConnectStateProps from "../../utils/state.connect";
import OthersBusiness from "../onthego.transfer/others.business/others.business.component";
import MyselfNewTransfer from '../onthego.transfer/Myself'
import SomeoneComponent from "../onthego.transfer/others.SomeOneElse/someone.component"
const FiatAddress = ({ onSubmit, onAddressOptionsChange,selectedAddress, onContinue, PayeeLu = [], emailExist = false, countries = [], states = [], fiatAddress, onTheGoObj,reasonAddress, ...props }) => {
    const [form] = useForm();
    const addrType = (selectedAddress?.addressType || props.userProfile?.isBusiness)
  ? selectedAddress?.addressType?.toLowerCase() || "ownbusiness"
  : "myself";
    const [addressOptions, setAddressOptions] = useState({ addressType:addrType, transferType: props.currency === "EUR" ? "sepa" : "domestic" });
    return <>
        <Form
            form={form}
            onFinish={onSubmit}
            autoComplete="off"
            initialValues={fiatAddress}
        >
            <Form.Item
                name="addressType"
                className="custom-label text-center mb-0"
            >
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                        <Radio.Group
                            defaultValue={addressOptions.addressType}
                            className="new-custom-radiobtn"
                            onChange={(value) => {
                                setAddressOptions({ ...addressOptions, addressType: value.target.value });
                                onAddressOptionsChange({ ...addressOptions, addressType: value.target.value });
                            }}
                        >
                            <Radio.Button value={props.userProfile?.isBusiness?"ownbusiness":"myself"}><span className="lg icon" />{props.userProfile?.isBusiness ? "My Company" : "My Self"}</Radio.Button>
                            <Radio.Button value="someoneelse"><span className="lg icon" />Individuals</Radio.Button>
                            <Radio.Button value="otherbusiness"><span className="lg icon" />Other Business</Radio.Button>
                            
                        </Radio.Group>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
        {(addressOptions.addressType === "ownbusiness"||addressOptions.addressType === "myself") && <MyselfNewTransfer currency={props.currency} type={props.type} onContinue={(obj) => onContinue(obj)} {...props} isBusiness={props.userProfile?.isBusiness}
            onTheGoObj={{amount:props.amount}} selectedAddress={selectedAddress} selectedAccountId={props.selectedAccountId}></MyselfNewTransfer>}
        {addressOptions.addressType === "otherbusiness" && <OthersBusiness selectedAddress={selectedAddress} selectedAccountId={props.selectedAccountId} type={props.type} isUSDTransfer={props.currency === "USD" ? true : false} onContinue={(obj) => onContinue(obj)} amount={props.amount} reasonAddress={reasonAddress} />}
        {addressOptions.addressType === "someoneelse" && <SomeoneComponent selectedAddress={selectedAddress} selectedAccountId={props.selectedAccountId} addressType={addressOptions.addressType} type={props.type} currency={props.currency} onContinue={(obj) => onContinue(obj)} onTheGoObj={{amount:props.amount}}reasonAddress={reasonAddress} />}
    </>
}

export default ConnectStateProps(FiatAddress);
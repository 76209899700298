import React, { useEffect, useState } from "react";

import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Typography from "antd/lib/typography";
import Form from "antd/lib/form";
import List from "antd/lib/list";
import Button from "antd/lib/button";
import  message  from "antd/lib/message";
import Alert from "antd/lib/alert";
import DatePicker from "antd/lib/date-picker";
import Menu from "antd/lib/menu";
import Modal from "antd/lib/modal";
import Upload from "antd/lib/upload";
import Dropdown from "antd/lib/dropdown";
import  Space  from "antd/lib/space";
import Tooltip from "antd/lib/tooltip";
import Image from "antd/lib/image";
import {
  getAccountDetails,
  downloadConfirmation,
  previewData,isErrorDispaly
} from "../../api/apiCalls";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Loader from "../shared/loader";
import GridList from "../grid.component";
import moment from "moment";
import Recive from "../recive.component";
import SuissebaseDigitalWallet from "../suissebaseDigitalWallet";
import { onExcellExport } from "../grid.component/subscribir";
import OnthegoFundTransfer from "../onthego.transfer";
import NumberFormat from "react-number-format";
import TransactionSlips from "./transactionSlips";
const { Title, Text } = Typography;

const AccountView = (props) => {
  const gridRef = React.createRef();
  const [loading, setIsLoading] = useState(false);
  const [receiveEnable, setReceiveEnable] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [digitalWalletEnable, setDigitalWalletEnable] = useState(false);
  const [searchObj, setSearchObj] = useState({ fromdate: "", todate: "" });
  const [searchgridObj, setSearchgridObj] = useState({
    fromdate: "",
    todate: ""
  });
  const [error, setError] = useState("");
  const [sendEnable, setSendEnable] = useState(false);
  const [accountData, setaccountData] = useState([]);
  const [seelctedAccount, setSelectedAccount] = useState({});
  const [isRefreshDashBoard, setRefreshDashBoard] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [viewData, setViewData] = useState(null);
  const [selectedCoin, setSelectedCoin] = useState({});
  const [load, setLoad] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  useEffect(() => {
    getCustomerAccountDetails();
    if (props?.action == "transfer") {
      setSendEnable(true);
    } else if (props?.action == "receive") {
      setReceiveEnable(true);
    }
    else if (props?.action == "digitalwallet") {
      setDigitalWalletEnable(true);
    }
  }, []);
  useEffect(() => {
    if (isRefreshDashBoard) {
      getCustomerAccountDetails();
      setRefreshDashBoard(false);
    }
  }, [isRefreshDashBoard]);
  useEffect(() => {
    gridRef.current?.refreshGrid();
  }, [searchgridObj]);
  useEffect(() => {
    gridRef?.current?.refreshGrid();
  }, [isCheck]);

  const getCustomerAccountDetails = async () => {
    setIsLoading(true);
    let response = await getAccountDetails();
    if (response.ok) {
      setError(null)
      setaccountData(response.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setError(isErrorDispaly(response));
      setIsLoading(false);
    }
  };

  const refreshDashBoard = (isRefresh) => {
    setRefreshDashBoard(isRefresh);
  };
  const handleChange = (val, type) => {
    let formatDate = val ? moment(val).format("YYYY-MM-DD") : "";
    if (type === "fromdate" || type === "todate") {
      searchObj[type] = formatDate;
      setSearchObj(searchObj);
      if (!formatDate) {
        searchgridObj[type] = "";
        setSearchgridObj(searchgridObj);
      }
    } else {
      searchObj[type] = val;
      setSearchObj(searchObj);
      if (!val) {
        searchgridObj[type] = "";
        setSearchgridObj(searchgridObj);
      }
    }
  };

  const handleSearch = () => {
    if (searchObj.todate == "" && searchObj.fromdate == "") {
      gridRef.current.refreshGrid();
    } else if (searchObj.todate === "") {
      setError("Please select the To Date");
      window.scrollTo(0, 0);
    } else if (searchObj.fromdate === "") {
      setError("Please select the From Date");
      window.scrollTo(0, 0);
    } else if (searchObj.todate < searchObj.fromdate) {
      setError("To Date must be greater than or equal to From Date.");
      window.scrollTo(0, 0);
    } else {
      setError(null);
      setSearchgridObj({
        fromdate: searchObj.fromdate,
        todate: searchObj.todate
      });
    }
  };
  const transactionModal = async (prop) => {
    setShowModal(true);
    setLoad(true);
    let response = await previewData(
      prop?.dataItem?.id,
      prop?.dataItem?.transactionType
    );
    if (response.ok) {
      setLoad(false);
      setError(null)
      setViewData(response.data);
      setSelectedCoin(prop?.dataItem);
    }else{
      setError(isErrorDispaly(response))
      setLoad(false)
    }
  };

  const handleModalCancel = () => {
    setShowModal(false);
  };
  const gridColumns = [
    {
      field: "transactionType",
      title: "Transaction Type",
      filter: true,
      width: 200,
      customCell: (data) => (
        <td className="d-flex justify-content">
          <div
            className="gridLink c-pointer"
            onClick={() => transactionModal(data)}
          >
            {data?.dataItem?.transactionType}
          </div>
        </td>
      )
    },
    {
      field: "createdAt",
      title: "Date",
      filter: true,
      isShowTime: true,
      filterType: "date",
      width: 250
    },
    { field: "currency", title: "Currency", filter: true, width: 200 },
    { field: "details", title: "Details", filter: true, width: 200 },
    {
      field: "deposit",
      title: "Deposit",
      filter: true,
      width: 200,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "withdrawal",
      title: "Withdrawal",
      filter: true,
      width: 200,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "balances",
      title: "Balances",
      filter: true,
      width: 200,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "bankTransactionStatus",
      title: "Transaction Status",
      filter: true,
      width: 200
    }
  ];

  const sendFunds = (item) => {
    setSelectedAccount(item);
    setSendEnable(true);
    setReceiveEnable(false);
  };

  const receiveFunds = (item) => {
    setSelectedAccount(item);
    setReceiveEnable(true);
    setDigitalWalletEnable(false);
  };

  const onClose = () => {
    setReceiveEnable(false);
    setDigitalWalletEnable(false);
    props?.history?.push("/dashboard");
  };

  const onCloseReceive = () => {
    setReceiveEnable(false);
    setDigitalWalletEnable(false);
    props?.history?.push("/dashboard");
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const suissebaseDigitalWallet = (item) => {
    setDigitalWalletEnable(true);
    setSelectedAccount(item);
    setReceiveEnable(false);
  };
  const menuBar = (item) => (
    <Menu>
      <ul className="pl-0 drpdwn-list">
        <li>
          <Link to={"/internaltransfer"} value={3} className="c-pointer">
            <span>Internal Transfer</span>
          </Link>
        </li>
        {props.digitalPermissionName && (
          <li onClick={() => suissebaseDigitalWallet(item)}>
            <Link value={3} className="c-pointer">
              <span> Transfer To SuisseBase Digital Wallet</span>
            </Link>
          </li>
       ) } 
        <li onClick={() => handleDownload(item)}>
          <Link value={4} className="c-pointer">
            <span> Account Confirmation Letter Download</span>
          </Link>
        </li>
      </ul>
    </Menu>
  );

  const checkBox = (e) => {
    setIsCheck(e.target.checked);
  };

  const onMenuItemClick = (item) => {
    props.history.push(`/createAccount/${item}`);
  };

  const handleDownload = async (item) => {
    if (item.currency == "USD") {
      setDownloadLoader(true);
    } 
    let response = await downloadConfirmation(
      item.id,
      item.currency,
      item.accountStatus
    );
    if (response.ok) {
      setError(null)
      setDownloadLoader(false);
      const pdfUrl = response.data;
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.target = '_blank';
      link.download = 'file.pdf';
      link.click();
      message.success({
        content: "Downloaded successfully",
        className: "custom-msg",
        duration: 3
      });
    } else {
      setDownloadLoader(false);
      setError(isErrorDispaly(response));
    }
  };

  return (
    <>
      {error !== undefined && error !== null && error !== "" && (
        <div style={{ width: "100%" }}>
          <Alert
            className="w-100 mb-16"
            type="error"
            description={error}
            showIcon
          />
        </div>
      )}
      {loading ? (
        <Loader />
      ) : (

        <div>
          <Row className="dashboard-cards">
            {accountData.map((account) => {
              
              return (
                <Col xs={24} sm={24} lg={12} xl={12} xxl={12}>
                  <div className="custom-createaccount creat-bg mb-16 new-createbg">
                    <List
                      itemLayout="horizontal"
                      dataSource={[account]}
                      renderItem={(item) => (
                        <List.Item className="py-10 px-0 accountcard-list">
                          <List.Item.Meta
                            avatar={
                              <Image preview={false} src={item.imagePath} />
                            }
                            title={
                              <div className="fs-16 fw-600 text-upper text-white-30 l-height-normal">
                                {props.userConfig?.isBusiness
                                  ? "Business"
                                  : "Personal"}{" "}
                                {item.currency} {"account"}
                                <div>{account?.accountNumber ? account?.accountNumber : "--"}</div>
                              </div>
                            }
                            description={
                              <>
                                <div className="d-flex">
                                  {item.availableBalance !== null && (
                                    <div className="mr-16">
                                      <Text className="case-lbl">
                                        Available{" "}
                                      </Text>
                                      <div className="case-val d-flex">
                                        {item.availableBalance !== null ? (
                                          <NumberFormat
                                            value={item.availableBalance}
                                            className="text-white-30 fw-600 mr-4"
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={
                                              item.currency == "USD" ? "$" : "€"
                                            }
                                            renderText={(value, props) => (
                                              <div {...props}>{value}</div>
                                            )}
                                          />
                                        ) : (
                                          0
                                        )}{" "}
                                        {item.currency}
                                      </div>
                                    </div>
                                  )}
                                  {(item.totalBalance !== null) && (
                                    <div>
                                      <Text className="case-lbl">
                                        Total Balance{" "}
                                      </Text>
                                      <div className="case-val d-flex">
                                        {item.totalBalance !== null  ? (
                                          <NumberFormat
                                            value={item.totalBalance}
                                            className="text-white-30 fw-600 mr-4"
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={
                                              item.currency == "USD" ? "$" : "€"
                                            }
                                            renderText={(value, props) => (
                                              <div {...props}>{value}</div>
                                            )}
                                          />
                                        ) : (
                                          0
                                        )}{" "}
                                        {item.currency}
                                      </div>
                                    </div>
                                  )}
                                </div>
                               
                              </>
                            }
                          />
                                {item?.accountStatus?.toLowerCase() ==
                                  "approved" && (
                                    <>
                                      {" "}
                                      <div className="accountview-card">
                                        <Tooltip title={"Account confirmation"}>

                                        </Tooltip>
                                      </div>
                                    </>
                                  )}
                          {item.isAccountExist ? (
                            <>
                              {item?.accountStatus?.toLowerCase() ==
                                "approved" && (
                                 <>
                                <div className="crypto-btns menu-dots">
                                  

                                  <Dropdown
                                    overlay={menuBar(item)}
                                    trigger={["click"]}
                                    placement="bottomCenter"
                                    arrow
                                    overlayClassName="secureDropdown depwith-drpdown"
                                  >
                                    <a onClick={(e) => e.preventDefault()}>
                                      <Space>
                                        <span class="icon md menu-bar ml-4 p-relative"></span>
                                      </Space>
                                    </a>
                                  </Dropdown>
                                </div>
                                <div className="send-recieve-btns">
                                    {props.sendPermissionName && (
                                      <Button
                                        type="primary"
                                    className="custom-btn prime text-purewhite"
                                        onClick={() => sendFunds(item)}
                                        disabled={
                                          !(
                                            item.availableBalance &&
                                            item.availableBalance > 0
                                          )
                                        }
                                      >
                                        {" "}
                                        Send
                                      </Button>)}
                                    {props?.receivedPermissionName && (
                                      <Button
                                        type="primary"
                                    className="custom-btn sec"
                                        htmlType="submit"
                                        onClick={() => receiveFunds(item)}
                                      >
                                        Receive{" "}
                                      </Button>)}
                                  </div>
                                </>
                                )}

                              {item?.accountStatus?.toLowerCase() !=
                                "approved" && (
                                  <div className="crypto-btns mt-8 pending-cursors ">
                                    <div
                                      content="Pending"
                                      type="primary"
                                      className="custom-btn prime pending-cursors text-white text-center py-8"
                                    >
                                      {item?.accountStatus}
                                    </div>
                                  </div>
                                )}
                            </>
                          ) : (
                            <div className="crypto-btns mt-8">
                              {props.dashboardPermissions?.createaccount && (
                                <Button
                                  content="Pending"
                                  type="primary"
                                  className="custom-btn prime"

                                  onClick={() =>
                                    onMenuItemClick(item.currency)
                                  }
                                >
                                  Create Account
                                </Button>
                              )}
                            </div>
                          )}
                        </List.Item>
                      )}
                    />
                  </div>
                </Col>
              );
            })}
          </Row>
          <div className="">
            <div>
              <Title className="db-titles my-30">
                {isCheck && "Pending Transactions"}
                {!isCheck && "Transaction History"}
              </Title>
              <Form className="form form-bg search-bg">
                <Row gutter={24} style={{ rowGap:'0px', margin:'0' }} className="filter-content">
                  <Col xs={24} sm={24} md={24} xl={6} xxl={6} >
                    <Form.Item
                      name="fromdate"
                      className="input-label mb-0 ml-0 c-pointer"
                      label="From Date"
                    >
                      <DatePicker
                        className="cust-input bgwhite custom-datepiker cust-date-width newcust-bg"
                        placeholder="Select From Date"
                        format={"DD/MM/YYYY"}
                        onChange={(e) => handleChange(e, "fromdate")}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} xl={6} xxl={6} >
                    <Form.Item
                      name="todate"
                      className="input-label mb-0 ml-0 c-pointer"
                      label="To Date"
                    >
                      <DatePicker
                        className="cust-input  bgwhite custom-datepiker cust-date-width newcust-bg"
                        placeholder="Select To Date"
                        format={"DD/MM/YYYY"}
                        onChange={(e) => handleChange(e, "todate")}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} xl={5} xxl={4} className="px-8">
                    <Button
                      className="pop-btn search-btn"
                   
                      onClick={handleSearch}
                    >
                      Search {""}
                      <span class="icon md search-angle"></span>
                    </Button>
                  </Col>

                  <Col
                    xs={18}
                    sm={18}
                    md={24}
                    xl={4}
                    xxl={4}
                    className="px-8 pending-col"
                  >
                    <label
                      className="text-center custom-checkbox"
                      style={{ color: "white" }}
                    >
                      <input
                        name="check"
                        type="checkbox"
                        checked={isCheck}
                        onChange={checkBox}
                        className="grid_check_box"
                      />
                      <span> </span> <span className="pending-transactions">Pending Transactions</span>
                    </label>
                  </Col>
                </Row>
              </Form>
              <div className="text-right export-pdf secureDropdown">
                 <Tooltip placement="top" title={"Export Excel"}>
                      <button className="c-pointer pop-btn" onClick={() => {
                          onExcellExport();
                        }}>
                        <span>Download Transaction</span>
                      <span
                        className="icon xl mr-0 excelexport"
                       
                      ></span></button>
                    </Tooltip>
                 </div>

              <div
                className="cust-list custom-grd-view"
                style={{ clear: "both" }}
              >
                <GridList className="cust-kendo-grid"
                  showActionBar={true}
                  additionalParams={searchgridObj}
                  showExcelExport={true}
                  url={
                    process.env.REACT_APP_GRID_API +
                    `Bank/AllBanksTransactions/${isCheck ? "pending" : "All"}`
                  }
                  columns={gridColumns}
                  ref={gridRef}
                  excelFileName={"All Transactions"}
                />
              </div>
            </div>

            <Modal
              title="Upload Documents :"
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <textarea>Upload Here</textarea>
              <Upload
                accept=".pdf,.jpg,.jpeg,.png, .PDF, .JPG, .JPEG, .PNG"
                showUploadList={false}
              >
                <span className="icon md attach mr-16 c-pointer" />
              </Upload>
            </Modal>

            {receiveEnable && (
              <Recive
                onreciveClose={() => onCloseReceive()}
                reciveId={seelctedAccount.id || props.id}
                currency={seelctedAccount.currency || props.currency}
                action={props.action}
              />
            )}


            {digitalWalletEnable && (
              <SuissebaseDigitalWallet
                currency={seelctedAccount.currency || props.currency}
                action={props.action}
                id={seelctedAccount.id || props.id}
                onDigitalWalletClose={() => onClose()}
                refreshDashBoard={(isRefresh) => refreshDashBoard(isRefresh)}
              />
            )}
          </div>
        </div>
      )}
      {sendEnable && (
        <OnthegoFundTransfer
        availableBalance={seelctedAccount?.availableBalance}
          selectedCurrency={seelctedAccount.currency || props.currency}
          selectedAccountId={seelctedAccount.id || props.id}
          action={props.action}
          onClose={() => {
            setSendEnable(false);
            props?.history?.push("/dashboard");
          }}
          refreshDashBoard={(isRefresh) => refreshDashBoard(isRefresh)}
        />
      )}
      <TransactionSlips
        showModal={showModal}
        handleCancel={handleModalCancel}
        viewData={viewData}
        load={load}
        downLoadButtonLoader={downloadLoader}
        id={selectedCoin.id}
      />
    </>
  );
};

const connectStateToProps = ({
  userConfig,
  transferData,
  digitalWalletData
}) => {
  return {
    userConfig: userConfig.userProfileInfo,
    transferData,
    digitalWalletData
  };
};
const connectDispatchToProps = (dispatch) => {
  return {
    dispatch
  };
};

export default connect(
  connectStateToProps,
  connectDispatchToProps
)(withRouter(AccountView));

import {apiMain, bankClient} from "../../api/clients"
import{ApiControllers} from '../../api/config'

const getCode = (type) => {
	return bankClient.get(
		ApiControllers.addressbook + `SendOTP/${type}`
	);
};
const getVerification = (code) => {
	return bankClient.get(
		ApiControllers.addressbook + `OTPVerification/${code}`
	);
};

const sendEmail = (isResendOTP) => {
	return apiMain.get(
		ApiControllers.master + `SendEmailOTP/${isResendOTP}`
	);
};


const verifyEmailCode = (code) => {
	return apiMain.get(
		ApiControllers.master + `EmailOTPVerification/${code}`
	);
};

const getAuthenticator = (Code) => {
	return bankClient.get(
		ApiControllers.addressbook +`VerifyAuthenticator/${Code}`
	);
};

const getVerificationFields = () => {
	return bankClient.get(
		ApiControllers.addressbook + `Verificationfields`
	);
};

export {getCode,getVerification,sendEmail,verifyEmailCode,getAuthenticator,getVerificationFields}

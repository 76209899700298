import React, { useState } from 'react';

import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Typography from "antd/lib/typography";
import Form from "antd/lib/form";
import Button from "antd/lib/button";
import Input from "antd/lib/input";
import Checkbox from 'antd/lib/checkbox';
import Loader from './loader';

const Confirmation = (props) => {
    const [agreeRed] = useState(true);
    const { Text, Title } = Typography;

    if(props?.isButtonLoading){
        <Loader/>
    }
    const { transferDetails, isButtonLoading, verification, isCheck, status, state,isButtonShow, imageIcon,isEmailBtnLoading } = props;
    
    const backTodashboard = () => {
          props.closeDrawer()
        }
    return (<>
        <Form
            name="advanced_search"
            onFinish={(val) => props?.emailSend(val)}
            autoComplete="off">
            {status && <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                    <div className='text-center'>
                    <img src={imageIcon} className="inprogress-state confirm-icons ml-8" alt={'success'} />
                        <div className="mb-8 fs-14 text-white fw-500 text-center mt-16" style={{ position: "relative" }} >Your Payment is {state} </div>
                    </div>
                </Col>
                {"  "}
            </Row>}
            {
                transferDetails?.map((item) =><>
                            <div className="d-flex justify-content" style={{ alignItems: 'baseline' }}>
                                <Text className="adbook-head">{item?.name}</Text>
                            </div>
                        {"  "}
                        <div className='cust-summary-new new-transfer-style'>{item?.details?.map((items) =>
                                <div className='pay-list'>
                                    <Title className="summary-liststyle">{items?.key}</Title>
                                    <Title className="summarybal">{items?.amount}{" "}{items?.currency}</Title>
                                    </div>
                                    // </Col>
                                   
                        )} </div>
                   </>
                )
            }
          
            {isButtonShow && <Row gutter={24} className=" text-white mt-36">
                <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                    <div className="text-center mt-36">
                        <Form.Item className="mb-0 mt-16 btn-unblock">
                            <Button
                                size="large"
                                block
                                className="pop-btn px-24"
                                onClick={()=>backTodashboard()}                               
                                 loading={isButtonLoading}
                            >
                                Go To dashboard
                            </Button>
                        </Form.Item>
                    </div>
                </Col>
            </Row>}
            {verification && <Row gutter={24} className=" text-white mt-36">
                <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                    <Form.Item
                        className="custom-forminput mb-36 agree"
                        name="isAccept"
                        valuePropName="checked"
                        required
                    >
                        <div className="d-flex align-center">
                            <Checkbox className={`ant-custumcheck ${!agreeRed ? "check-red" : " "}`} onClick={(e) => props?.checkEmail(e)} />
                            <span className="withdraw-check"></span>
                            <Text className="fs-14 text-white-30 ml-16">
                                I would like to send a confirmation email to this recipient
                            </Text>
                        </div>
                    </Form.Item>
                    {isCheck === true &&
                        <Form.Item
                            className="input-label mb-36 "
                            name="receipentEmail"
                            type='email'
                            rules={[
                                { required: true, message: "Is required" },
                                {
                                    validator(_, value) {
                                        
                                        if (value && !(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,15}(?:\.[a-z]{2})?)$/.test(value))) {
                                            return Promise.reject("Invalid email");
                                        }
                                        else {
                                            return Promise.resolve();
                                        }
                                    },
                                },]}
                        >

                            <div className="">
                                <label className="fs-14 text-white-30 ml-16 mb-4">
                                    Enter recipient email :
                                </label>
                                <Input placeholder="Enter Email" className="cust-input" maxLength={50} />
                            </div>
                        </Form.Item>

                    }
                </Col>
            </Row>}
            {isCheck && <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                <div className="text-center mt-36 create-account">
                    <Form.Item className="mb-0 mt-16">
                        <Button
                             htmlType="submit"
                            size="large"
                            block
                            className="pop-btn px-24"
                            loading={isEmailBtnLoading}
                        >
                            Send email to recipient
                        </Button>
                    </Form.Item>
                </div>
            </Col>}
        </Form>
    </>)
}
export default Confirmation
import { Alert, Tabs } from "antd";
import { Form, Row, Col,Typography, Input, Button, Image,Spin } from "antd";
import React, { Component } from "react";
import Loader from "../../shared/loader";
import { validateContentRule,validateFeild} from "../../../utils/custom.validator";
import ConnectStateProps from "../../../utils/state.connect";
import AddressDocumnet from "../document.upload";
import { RecipientAddress } from "./recipient.details";
import { confirmTransaction, createPayee, payeeAccountObj, savePayee } from "../api";
import DomesticTransfer from "./domestic.transfer";
import InternationalTransfer from "./international.transfer";
import alertIcon from '../../../assets/images/pending.png';
import {fetchIBANDetails} from '../api'
import { isErrorDispaly } from "../../../api/apiCalls";
const { TextArea } = Input;

const { Paragraph, Title, Text } = Typography;
class BusinessTransfer extends Component {
    useDivRef = React.createRef()
    form = React.createRef();
    state = {
        errorMessage: null,
        isLoading: true,
        details: {},
        selectedTab: "domestic", isBtnLoading: false,
        showDeclaration: false,
        ibanDetails: {},
        ibanDetailsLoading: false,
        isValidateLoading: false ,
    };
    componentDidMount() {
        this.loadDetails();
    }
    loadDetails = async () => {
        this.setState({ ...this.state, errorMessage: null, isLoading: true });
        const response = await createPayee(this.props.selectedAddress?.id || "", "otherbusiness");
        if (response.ok) {
            let data = response.data;let edit=false;
            if (!data?.payeeAccountModels) {
                data.payeeAccountModels = [payeeAccountObj()];
            }
            if (this.props.selectedAddress?.id) {
                const accountDetails = data.payeeAccountModels[0];
                data = { ...data, ...accountDetails, line1: data.line1, line2: data.line2, line3: data.line3, bankAddress1: accountDetails.line1, bankAddress2: accountDetails.line2 };
                delete data["documents"];
                 edit = true;
            }
            if(data.transferType== "international"){
                this.setState({ ...this.state, selectedTab:data.transferType })
            }
            if(data.transferType== "internationalIBAN"){
                this.setState({ ...this.state, selectedTab:data.transferType })
                 this.handleIbanChange({ target: { value: data?.iban, isNext: true } });
            }
            else{
                this.setState({ ...this.state, selectedTab:"domestic" })  
            }
            const ibanDetails = response.data?.payeeAccountModels[0] || {}
            this.setState({ ...this.state, errorMessage: null, details: data,isEdit:edit, isSelectedId:  response.data?.id, ibanDetails}, () => {
                this.setState({ ...this.state, isLoading: false })
            });
        } else {
            this.setState({ ...this.state, errorMessage:isErrorDispaly(response), isLoading: false, details: {} });
        }

    }
    submitPayee = async (values) => {
        let { details, selectedTab,isEdit,isSelectedId, ibanDetails } = this.state;
        let _obj = { ...details, ...values };
        if (Object.hasOwn(values, 'iban')) {
            this.setState({ ...this.state, errorMessage: null});
            if ((!ibanDetails || Object.keys(ibanDetails).length == 0)) {
                this.setState({ ...this.state, errorMessage: "Please click validate button before saving", isLoading: false, isBtnLoading: false });;
                this.useDivRef.current.scrollIntoView()
                return;
            }
            }
        _obj.payeeAccountModels[0].currencyType = "Fiat";
        _obj.payeeAccountModels[0].walletCode = "USD";
        _obj.payeeAccountModels[0].accountNumber = values?.accountNumber;
        _obj.payeeAccountModels[0].bankName = selectedTab == "internationalIBAN" ? ibanDetails?.bankName :  values?.bankName;
        _obj.payeeAccountModels[0].abaRoutingCode = values?.abaRoutingCode;
        _obj.payeeAccountModels[0].swiftRouteBICNumber = values?.swiftRouteBICNumber;
        _obj.payeeAccountModels[0].line1 = selectedTab == "internationalIBAN" ? ibanDetails?.bankAddress : values.bankAddress1;
        _obj.payeeAccountModels[0].line2 = values.bankAddress2;
        _obj.payeeAccountModels[0].documents.customerId = this.props?.userProfile?.id;
        _obj.addressType = "otherbusiness";
        _obj.transferType = selectedTab;
        _obj.amount = this.props.amount;
        _obj.payeeAccountModels[0].city = ibanDetails?.city;
        _obj.payeeAccountModels[0].state = ibanDetails?.state;
        _obj.payeeAccountModels[0].country = ibanDetails?.country;
        _obj.payeeAccountModels[0].postalCode = ibanDetails?.zipCode;
        _obj.payeeAccountModels[0].bankBranch = ibanDetails?.branch;
        _obj.payeeAccountModels[0].bic=ibanDetails?.routingNumber;
        _obj.payeeAccountModels[0].iban = values?.iban ? values?.iban : this.form.current?.getFieldValue('iban');
        if(isEdit){
            _obj.id = isSelectedId? isSelectedId:details?.payeeId;
        }
        delete _obj.payeeAccountModels[0]["adminId"] // deleting admin id
        this.setState({ ...this.state, errorMessage: null, isLoading: false, isBtnLoading: true });
        const response = await savePayee(_obj);
        if (response.ok) {
            if (this.props.type != "manual") {
                const confirmRes = await confirmTransaction({ payeeId: response.data.id, amount: this.props.amount, reasonOfTransfer: _obj.reasonOfTransfer,AccountId: this.props.selectedAccountId })
                if (confirmRes.ok) {
                    this.props.onContinue(confirmRes.data);
                    this.setState({ ...this.state, isLoading: false, errorMessage: null, isBtnLoading: false });
                } else {
                    this.setState({ ...this.state, errorMessage: isErrorDispaly(response) ,isLoading: false, isBtnLoading: false });
                }
            } else {
                this.setState({ ...this.state, isLoading: false, errorMessage: null, isBtnLoading: false, showDeclaration: true });
            }
        } else {
            this.setState({ ...this.state, details: { ...details, ...values }, errorMessage: isErrorDispaly(response), isLoading: false, isBtnLoading: false });
        }
    }
    handleTabChange = (key) => {
        let _obj = { ...this.state.details}
        _obj.payeeAccountModels[0].documents=null
        this.setState({ ...this.state, selectedTab: key,errorMessage:null, ibanDetails: {}, iBanValid: false, enteredIbanData: null });
        this.form.current.resetFields();
    }
    handleIbanChange = async ({ target: { value,isNext } }) => {
        this.setState({ ...this.state, enteredIbanData: value, isShowValid: false,isValidateLoading:true});
        if (value?.length > 10 && isNext) {
            this.setState({ ...this.state, errorMessage: null, ibanDetailsLoading: true,iBanValid:true,isValidateLoading:true });
            const response = await fetchIBANDetails(value);
            if (response.ok) {
                if(response.data && (response.data?.routingNumber || response.data?.bankName)){
                    this.setState({ ...this.state, ibanDetails: response.data, ibanDetailsLoading: false, errorMessage: null, iBanValid:true, isValidateLoading: false });
                }else{
                    this.setState({ ...this.state, ibanDetails: response.data, ibanDetailsLoading: false, errorMessage: null, iBanValid:false, isValidateLoading: false });
                }
            } else {
                this.setState({ ...this.state, ibanDetailsLoading: false,iBanValid:false, errorMessage: isErrorDispaly(response), isValidateLoading: false });
            }
        }
        else{
            this.setState({ ...this.state, ibanDetailsLoading: false,iBanValid:false, enteredIbanData: value, isShowValid: false, isValidateLoading: false, ibanDetails: {} })
        }
    }
    onIbanValidate = (e) => {
        this.setState({...this.state,isValidateLoading:true})
        let value = e ? e: this.form.current?.getFieldValue('iban');
        if (value?.length > 10) {
            if (value &&!/^[A-Za-z0-9]+$/.test(value)) {
                this.setState({ ...this.state, isValidCheck: false, isShowValid: true, iBanValid: false, ibanDetails: {}, isValidateLoading: true, isValidateMsg: true, errorMessage: null});
                this.form.current?.validateFields(["iban"], this.validateIbanType)
            }
            else {
                this.setState({ ...this.state, isValidCheck: true, isShowValid: false, isValidateLoading: true});
                this.handleIbanChange({ target: { value: value, isNext: true }});
            }
        }
        else {
            this.setState({ ...this.state, isValidCheck: false, isShowValid: true, iBanValid: false, ibanDetails: {}, isValidateLoading: true, isValidateMsg: true, errorMessage: null});
            this.form.current?.validateFields(["iban"], this.validateIbanType)
        }
    }

     validateIbanType = (_, value) => {
        this.setState({ ...this.state, isValidateLoading: false});
        if ((!value&&this.state.isShowValid) ||(!value)) {
            return Promise.reject("Is required");
        } else if ((!this.state.iBanValid&&this.state.isShowValid)|| value?.length < 10) {
            return Promise.reject("Please input  valid IBAN");
        } else if (
            value &&this.state.isShowValid&&
            !/^[A-Za-z0-9]+$/.test(value)
        ) {
            return Promise.reject(
                "Please input  valid IBAN"
            );
        }
        else {
            return validateContentRule(_, value);
        }
    };
  
    render() {
        const { isLoading, details, selectedTab, errorMessage } = this.state;
        if (isLoading) {
            return <Loader />
        }
      
        if (this.state.showDeclaration) {
            return <div className="text-center">
                <Image width={80} preview={false} src={alertIcon} className="confirm-icons"/>
                <Title level={2} className="success-title">Declaration form sent successfully to your email</Title>
                <Text className="successsubtext">{`Declaration form has been sent to ${this.props.userProfile?.email}. 
                   Please sign using link received in email to whitelist your address. `}</Text>
                <Text className="successsubtext">{`Please note that your withdrawal will only be processed once your whitelisted address has been approved`}</Text>
                <div className="my-25"><Button onClick={() => this.props.onContinue({ close: true, isCrypto: false })} type="primary" className="mt-36 pop-btn text-textDark">BACK</Button></div>
            </div>
        }
        return <div ref={this.useDivRef}> <Tabs className="cust-tabs-fait fiat-tabscls" onChange={this.handleTabChange} activeKey={selectedTab}>
            <Tabs.TabPane tab="Domestic USD transfer" className="text-white" key={"domestic"} disabled={this.state.isEdit}>
                {errorMessage && <Alert type="error" description={errorMessage} showIcon />}
               
                <Form initialValues={details}
                    className="mb-0"
                    ref={this.form}
                    onFinish={this.submitPayee}
                    scrollToFirstError
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item
                                className="custom-forminput custom-label mb-0"
                                name="favouriteName"
                                label={"Save Whitelist Name As"}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: "Is required",
                                    },
                                    {
                                        whitespace: true,
                                        message: "Is required",
                                    },
                                    {
                                        validator: validateContentRule,
                                    },
                                ]}
                            >
                                <Input
                                    maxLength={100}
                                    className="cust-input"
                                    placeholder={" Save Whitelist Name As"}
                                />


                            </Form.Item>
                        </Col>
                    </Row>
                    <Paragraph style={{ fontSize: '18px' }}
                        className="mb-8  text-white fw-500 mt-16 px-8"
                    >Recipient's Details</Paragraph>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                className="custom-forminput custom-label mb-0"
                                name="beneficiaryName"
                                required
                                rules={[
                                    {
                                        validator:(_,val)=>{
                                            return validateFeild({val:val,required: true,maxLength:50,validateContentRule:true,lableName:'beneficiary name'})
                                        }
                                    },
                                    {
                                        whitespace: true,
                                        message: "Is required",
                                    },
                                ]}
                                label={
                                    "Beneficiary Name"
                                }
                            >
                                <Input
                                    className="cust-input"
                                    placeholder={"Beneficiary Name"}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                className="custom-forminput custom-label mb-0"
                                name="relation"
                                label={"Relationship To Beneficiary"}
                                required
                                rules={[
                                    {
                                        validator:(_,val)=>{
                                            return validateFeild({val:val,required: true,maxLength:50,validateContentRule:true,lableName:'relationship to beneficiary'})
                                        }
                                    },
                                    {
                                        whitespace: true,
                                        message: "Is required",
                                    },
                                ]}
                            >
                                <Input
                                    className="cust-input"
                                    placeholder={"Relationship To Beneficiary"}
                                />

                            </Form.Item>
                        </Col>
                        <RecipientAddress />
                    </Row>

                    <Paragraph className="mb-8 mt-16 text-white fw-500 px-8" style={{ fontSize: 18 }}>Bank Details</Paragraph>
                    <DomesticTransfer type={this.props.type} />
                    <Paragraph className="fw-500 mb-0 pb-4 ml-12 text-white-50 pt-16">Please upload supporting docs for transaction*</Paragraph>
                    <AddressDocumnet documents={this.state?.details?.payeeAccountModels[0]?.documents || null} editDocument={this.state.isEdit} onDocumentsChange={(docs) => {
                        let { payeeAccountModels } = this.state.details;
                        payeeAccountModels[0].documents = docs;
                        this.setState({ ...this.state, details: { ...this.state.details, payeeAccountModels } })
                    }} refreshData ={selectedTab}/>
                    <div className="text-right mt-12">
                        <Button
                            htmlType="submit"
                            size="large"
                            className="pop-btn mb-36"
                            loading={this.state.isBtnLoading}>
                            {this.props.type === "manual" && "Save"}
                            {this.props.type !== "manual" && "Continue"}
                        </Button>
                    </div>
                </Form>
            </Tabs.TabPane>
            <Tabs.TabPane tab="International USD Swift" key={"international"}>
                {errorMessage && <Alert type="error" description={errorMessage} showIcon />}
                <Form initialValues={details}
                    className="mb-0"
                    ref={this.form}
                    onFinish={this.submitPayee}
                    scrollToFirstError
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item
                                className="custom-forminput custom-label mb-0"
                                name="favouriteName"
                                label={"Save Whitelist Name As"}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: "Is required",
                                    },
                                    {
                                        whitespace: true,
                                        message: "Is required",
                                    },
                                    {
                                        validator: validateContentRule,
                                    },
                                ]}
                            >
                                <Input
                                   maxLength={100}
                                    className="cust-input"
                                    placeholder={" Save Whitelist Name As"}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Paragraph className="mb-8  text-white fw-500 mt-16 px-8" style={{ fontSize: '18px' }} >Recipient's Details</Paragraph>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                className="custom-forminput custom-label mb-0"
                                name="beneficiaryName"
                                required
                                rules={[
                                    {
                                        validator:(_,val)=>{
                                            return validateFeild({val:val,required: true,maxLength:50,validateContentRule:true,lableName:'beneficiary name'})
                                        }
                                    },
                                    {
                                        whitespace: true,
                                        message: "Is required",
                                    },
                                ]}
                                label={
                                    "Beneficiary Name"
                                }
                            >
                                <Input
                                    className="cust-input"
                                    placeholder={"Beneficiary Name"}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                className="custom-forminput custom-label mb-0"
                                name="relation"
                                label={"Relationship To Beneficiary"}
                                required
                                rules={[
                                    {
                                        validator:(_,val)=>{
                                            return validateFeild({val:val,required: true,maxLength:50,validateContentRule:true,lableName:'relationship to beneficiary'})
                                        }
                                    },
                                    {
                                        whitespace: true,
                                        message: "Is required",
                                    },
                                ]}
                            >
                                <Input
                                    className="cust-input"
                                    placeholder={"Relationship To Beneficiary"}
                                />

                            </Form.Item>
                        </Col>
                        <RecipientAddress />
                    </Row>

                    <Paragraph className="mb-8 text-white fw-500 mt-16 px-8" style={{ fontSize: 18 }}>Bank Details</Paragraph>
                    <InternationalTransfer type={this.props.type} />
                    <Paragraph className="fw-500 mb-0 pb-4 ml-12 text-white-50 pt-16">Please upload supporting docs for transaction*</Paragraph>
                    <AddressDocumnet documents={this.state?.details?.payeeAccountModels[0]?.documents || null} onDocumentsChange={(docs) => {
                        let { payeeAccountModels } = this.state.details;
                        payeeAccountModels[0].documents = docs;
                        this.setState({ ...this.state, details: { ...this.state.details, payeeAccountModels } })
                    }} />
                    <div className="text-right mt-12">
                        <Button
                            htmlType="submit"
                            size="large"
                            className="pop-btn mb-36"
                            loading={this.state.isBtnLoading}>
                            {this.props.type === "manual" && "Save"}
                            {this.props.type !== "manual" && "Continue"}
                        </Button>
                        {/* </Col>
                        </Row> */}
                    </div>
                </Form>

            </Tabs.TabPane>
            <Tabs.TabPane tab="International USD IBAN" key={"internationalIBAN"} disabled={this.state.isEdit}>
            <div>{errorMessage && <Alert type="error" description={errorMessage} showIcon />}
                <Form initialValues={details}
                    className="custom-label  mb-0"
                    ref={this.form}
                    onFinish={this.submitPayee}
                    scrollToFirstError
                >
                    <Row gutter={[16,16]}>
                        <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item
                                className="fw-300 mb-8 px-4 text-white-50 pt-16 custom-forminput custom-label"
                                name="favouriteName"
                                label={"Save Whitelist Name As"}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: "Is required",
                                    },
                                    {
                                        whitespace: true,
                                        message: "Is required",
                                    },
                                    {
                                        validator: validateContentRule,
                                    },
                                ]}
                            >
                                <Input
                                    maxLength={100}
                                    className="cust-input"
                                    placeholder={" Save Whitelist Name As"}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Paragraph className="mb-8 text-white fw-500 mt-16 px-4" style={{ fontSize: 18 }} >Recipient's Details</Paragraph>
                    {/* <Divider /> */}
                    <Row gutter={[16,16]}>
                    <div className="d-flex">
                        <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                className="custom-forminput custom-label fw-300 mb-8 px-4 text-white-50 py-4"
                                name="beneficiaryName"
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: "Is required",
                                    },
                                    {
                                        whitespace: true,
                                        message: "Is required",
                                    },
                                    {
                                        validator: validateContentRule,
                                    },
                                ]}
                                label={
                                    "Beneficiary Name"
                                }
                            >
                                <Input
                                    className="cust-input"
                                    placeholder={"Beneficiary Name"}
                                    maxLength={100}/>
                            </Form.Item>
                            </Col>
                        <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
                            <Form.Item
                                className="custom-forminput custom-label fw-300 mb-8 px-4 text-white-50 py-4"
                                name="relation"
                                label={"Relationship To Beneficiary"}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: "Is required",
                                    },
                                    {
                                        whitespace: true,
                                        message: "Is required",
                                    },
                                    {
                                        validator: validateContentRule,
                                    },
                                ]}
                            >
                                <Input
                                    className="cust-input"
                                    placeholder={"Relationship To Beneficiary"}
                                    maxLength={100}/>

                            </Form.Item>
                        </Col>
                        </div>
                        <RecipientAddress />
                    </Row>



                    <Paragraph className="mb-8 text-white fw-500 mt-36 px-4" style={{ fontSize: 18 }}>Bank Details</Paragraph>
                    <Row gutter={[8,8]}>
                        <div className="d-flex">
                       <Col xs={24} md={14} lg={14} xl={14} xxl={14}>
                  
                         <div className=" custom-btn-error">
                            <Form.Item
                              className="custom-forminput custom-label fw-300 mb-8 px-4 text-white-50 pt-8"
                              name="iban"
                                label={"IBAN"}
                                required
                                rules={[
                                    {
                                        validator: this.validateIbanType,
                                      },
                                ]}
                            >
                                <Input
                                    className="cust-input"
                                    placeholder={"IBAN"}
                                    onChange={this.handleIbanChange}
                                    maxLength={50}/>

                            </Form.Item>
                            </div>
                       </Col>
                       <Col xs={24} md={10} lg={10} xl={10} xxl={10}>
                       <Button className={`pop-btn dbchart-link fs-14 fw-500`} style={{width:"150px",marginTop:"32px",height:"42px"}}
                            loading={this.state.isValidateLoading} 
                             onClick={() => this.onIbanValidate(this.state?.enteredIbanData)} >
                                <Text>Validate</Text>
                            </Button>
                        </Col>
                        </div>
                        
                    </Row>
                    <div className="box basic-info alert-info-custom mt-16">
                        <Spin spinning={this.state.ibanDetailsLoading}>
                        {this.state.iBanValid && <Row className="d-flex">
                            <Col xs={24} md={8} lg={24} xl={8} xxl={8} className="mb-16">
                                
                                <label className="fs-12 fw-500">
                                    Bank Name
                                </label>
                                <div className="pr-24"><Text className="fs-14 fw-400 text-white">{this.state.ibanDetails?.bankName || "-"}</Text></div>

                            </Col>
                            <Col xs={24} md={8} lg={24} xl={8} xxl={8} className="mb-16">
                                <label className="fs-12 fw-500 ">
                                BIC
                                </label>
                                <div className="pr-24"><Text className="fs-14 fw-400 text-white">{this.state.ibanDetails?.routingNumber || "-"}</Text></div>

                            </Col>
                            <Col xs={24} md={8} lg={24} xl={8} xxl={8} className="mb-16">
                                <label className="fs-12 fw-500 ">
                                    Branch
                                </label>
                                <div className="pr-24"><Text className="fs-14 fw-400 text-white">{this.state?.ibanDetails?.branch || "-"}</Text></div>

                            </Col>
                            <Col xs={24} md={8} lg={24} xl={8} xxl={8} className="mb-16">
                                <label className="fs-12 fw-500 ">
                                    Country
                                </label>
                                <div><Text className="fs-14 fw-400 text-white">{this.state?.ibanDetails?.country || "-"}</Text></div>

                            </Col>
                            <Col xs={24} md={8} lg={24} xl={8} xxl={8} className="mb-16">
                                <label className="fs-12 fw-500 ">
                                    State
                                </label>
                                <div><Text className="fs-14 fw-400 text-white">{this.state?.ibanDetails?.state || "-"}</Text></div>

                            </Col>
                            <Col xs={24} md={8} lg={24} xl={8} xxl={8} className="mb-16">
                                <label className="fs-12 fw-500 ">
                                    City
                                </label>
                                <div><Text className="fs-14 fw-400 text-white">{this.state?.ibanDetails?.city || "-"}</Text></div>

                            </Col>
                            <Col xs={24} md={8} lg={24} xl={8} xxl={8} className="mb-16">
                                <label className="fs-12 fw-500 ">
                                    Zip
                                </label>
                                <div><Text className="fs-14 fw-400 text-white">{this.state?.ibanDetails?.zipCode || "-"}</Text></div>

                            </Col>
                        </Row>}
                        {!this.state.iBanValid && !this.state.ibanDetailsLoading && <Row>
                            <Col xs={24} md={24} lg={24} xl={24} xxl={24} className="mb-16">
                                <div><Text className="info-details">No bank details available</Text></div>

                            </Col>
                        </Row>}
                        </Spin>
                       
                    </div>
                    {this.props?.type !== "manual" && 
                    <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item
                                className="custom-forminput custom-label mb-0"
                                name="reasonOfTransfer"
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: "Is required",
                                    },
                                    {
                                        whitespace: true,
                                        message:"Is required",
                                    },
                                    {
                                        validator: validateContentRule,
                                    },
                                ]}
                                label={
                                    "Reason For Transfer"
                                }
                            >
                                <TextArea
                                    placeholder={"Reason For Transfer"}
                                    className="cust-input cust-text-area address-book-cust"
                                    autoSize={{ minRows: 1, maxRows: 1 }}
                                    maxLength={200}
                                ></TextArea>
                            </Form.Item>
                        </Col>}
                    <Paragraph className="fw-400 mb-0 pb-4 ml-12 text-white pt-16">Please upload supporting docs to explain relationship with beneficiary*</Paragraph>
                    <AddressDocumnet documents={this.state?.details?.payeeAccountModels[0]?.documents || null} onDocumentsChange={(docs) => {
                        let { payeeAccountModels } = this.state.details;
                        payeeAccountModels[0].documents = docs;
                        this.setState({ ...this.state, details: { ...this.state.details, payeeAccountModels } })
                    }} refreshData = {selectedTab}/>
                    <div className="text-right mt-36">
                                <Button
                                    htmlType="submit"
                                    size="large"
                                    className="pop-btn mb-36"
                                    loading={this.state.isBtnLoading}>
                                    {this.props.type === "manual" && "Save"}
                                    {this.props.type !== "manual" && "Continue"}
                                </Button>
                    </div>
                </Form></div>

            </Tabs.TabPane>
        </Tabs></div>
    }
}

export default ConnectStateProps(BusinessTransfer);
import React, { useState } from 'react';

import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Alert from "antd/lib/alert";
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Spin  from 'antd/lib/spin';

import { saveDigitalWallet } from './api'
import { connect } from 'react-redux';
import { setDigitalWalletStep } from "../../reducers/digitalWalletReducer";
import Verifications from "../verification.component/verification";
import Confirmation from '../shared/confirmation';
import { isErrorDispaly } from "../../api/apiCalls";



const PastRecipientDetails = (props) => {
    const useDivRef = React.useRef(null);
    const [errorMsg, setErrorMsg] = useState();
    const [reviewDetailsLoading, setReviewDetails] = useState(false);
    const [verifyData, setVerifyData] = useState({});
    const [btnLoading, setBtnLoading] = useState(false);

    const [summaryDetails] = useState([
        {
            name: 'Transfer Details',
            details: [
                {
                    key: 'How much you will receive',
                    amount: props?.digitalWalletData?.summaryData.totalValue,
                    currency: props?.currencyObj?.currency
                },
                {
                    key: 'Total fees',
                    amount: props?.digitalWalletData?.summaryData.comission,
                    currency: props?.currencyObj?.currency
                },
                {
                    key: 'Withdrawal Amount',
                    amount: props?.digitalWalletData?.summaryData.requestedAmount,
                    currency: props?.currencyObj?.currency
                },
            ]
        }])

   

    
    const changesVerification = (obj) => {
        setVerifyData(obj);
    }

    const onReviewDetailsLoading = (val) => {
        setReviewDetails(val);
    }

    const handleSummary = async () => {
        setBtnLoading(true);
        if (verifyData?.verifyData) {
            if (verifyData.verifyData.isPhoneVerified) {
                if (!verifyData.isPhoneVerification) {
                    setErrorMsg("Please verify phone verification code");
                    useDivRef.current.scrollIntoView();
                    setBtnLoading(false);
                    return;
                }
            }
            if (verifyData.verifyData.isEmailVerification) {
                if (!verifyData.isEmailVerification) {
                    setErrorMsg("Please verify  email verification code")
                    useDivRef.current.scrollIntoView();
                    setBtnLoading(false);
                    return;
                }
            }
            if (verifyData.verifyData.twoFactorEnabled) {
                if (!verifyData.isAuthenticatorVerification) {
                    setErrorMsg("Please verify authenticator code");
                    useDivRef.current.scrollIntoView();
                    setBtnLoading(false);
                    return;
                }
            }
            if (
                verifyData.verifyData.isPhoneVerified == "" &&
                verifyData.verifyData.isEmailVerification == "" &&
                verifyData.verifyData.twoFactorEnabled == ""
            ) {
                setErrorMsg("Without Verifications you can't send. Please select send verifications from security section",)
                useDivRef.current.scrollIntoView();
                setBtnLoading(false);
                return
            }
        }
         else {
            setErrorMsg("Without Verifications you can't Procced.",)
            useDivRef.current.scrollIntoView();
            setBtnLoading(false);
            return
        }
        let obj = {
            amount: props?.amount,
            description: null,
            walletCode: props?.currencyObj?.currency,
            transferBy: props?.userConfig?.isBusiness ? props?.userConfig?.businessName : props?.userConfig?.firstName  + " "+ props?.userConfig?.lastName ,
            attachements : null
        }
        setBtnLoading(true);
        let response = await saveDigitalWallet(obj);
        if (response.ok) {
            setErrorMsg(null);
            props?.sendTransactionId(response?.data.transactionId);
            props?.changeStep("confimationSlip");
            setBtnLoading(false);
        }
        else {
            setErrorMsg(isErrorDispaly(response));
            setBtnLoading(false);
            useDivRef.current.scrollIntoView();
        }
    }
  
    return (
        <>
            <div ref={useDivRef}></div>
                {errorMsg && <Alert
                    description={errorMsg}
                    className="w-100 mb-16"
                    type="error"
                    showIcon
                />}
            <Spin spinning={reviewDetailsLoading}>
                <Form
                    name="advanced_search"
                    onFinish={() => handleSummary()}
                    autoComplete="off">
                            <Confirmation
                                transferDetails={summaryDetails}
                            />
                    <Verifications onchangeData={(obj) => changesVerification(obj)} onReviewDetailsLoading={(val) => onReviewDetailsLoading(val)} />
                    <Row gutter={24} className=" text-white mt-36">
                        <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                            <div className="text-right mt-36">
                                <Form.Item className="mb-0 mt-16">
                                    <Button
                                        htmlType="submit"
                                        size="large"
                                        block
                                        className="pop-btn px-24"
                                        loading={btnLoading}
                                    >
                                        Confirm and Continue
                                    </Button>
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </>
    )
}

const connectStateToProps = ({ userConfig, digitalWalletData }) => {
    return {
        userConfig: userConfig.userProfileInfo, digitalWalletData

    };
};
const connectDispatchToProps = dispatch => {
    return {
        changeStep: (stepcode) => {
            dispatch(setDigitalWalletStep(stepcode))
        },
        dispatch
    }

}
export default connect(connectStateToProps, connectDispatchToProps)(PastRecipientDetails)

import { Component } from "react";

import Typography from "antd/lib/typography";
import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu'
import Layout from "antd/lib/layout";
import Dropdown from "antd/lib/dropdown";
import logoColor from "../assets/images/SuisseBase.png";
import DefaultUser from "../assets/images/defaultuser.jpg";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import HeaderPermissionMenu from '../components/shared/permission/header.menu'
import {handleHeaderProfileMenuClick} from '../utils/pubsub'
import Wraplogout from '../auth/logout-component'
const { Text } = Typography;

class AppHeader extends Component {
  state={logoutuser:false}
 componentDidMount=()=>{
  this.setState({...this.state,sendPermissionName:this.props.sendPermissions?.actions[0].values,logoutuser:false})
 }
  showAccount = () => {
    this.props.history.push("/dashboard");
  };
 
  routeToDashboard = () => {
    this.props.history.push("/dashboard");
  };
  currentMenuKey(key){
    if (this.props.location.pathname.search(key) >= 0) {
      return this.props.location.pathname;
    }else{
      return key
    }
  }
  onMenuItemClick = (menuitem, menuKey) => {
    handleHeaderProfileMenuClick(menuitem, menuKey);
  }
  render() {
    const userProfileMenu = (
      <Menu
     
      >
        <div className="profile-dropdown">
          <div className="profile-btn" >
                <Text
      
                  content="manage_account"
                  component={Button}
                  size="medium"
                  block
                  className="text-white fs-12 c-pointer"
                  onClick={()=>window.open(`${process.env.REACT_App_EXCHANGE_URL}/userprofile/1`,"_self")}

                  >
                  Manage your Account
                </Text>
                </div>
          <ul className="pl-0 drpdwn-list">
            <li>
              <Link onClick={() => {
                    this.setState({...this.state,logoutuser:true})
                  }}>
                <Text
                  content="AuditLogs"
                  component={Text}
                  className="text-white-30"
                >
                  Logout
                </Text>
              </Link>
            </li>
          </ul>
        </div>
      </Menu>
    );
    return (
      <>
         <Layout className="layout">
          <section className="haead-main">
          <div className="tlv-header" id="area">
            <div className="login-user">
              <ul className="header-logo pl-0">
                <li className="p-relative">
                  {
                    <img
                      src={logoColor}
                      className="tlv-logo light c-pointer"
                      alt={"image,logo"}
                      onClick={this.routeToHome}
                    />
                  }
                </li>
              </ul>
            </div>
            <Menu
              theme="light"
              mode="horizontal"
              className="header-right mobile-headerview"
              selectedKeys={[this.props.location.pathname]}
            >
               {window.location.pathname.indexOf("/dashboard") > -1 && 
              <Menu.Item
                className="list-item header-send back-user"
                onClick={()=>window.open(`${process.env.REACT_App_EXCHANGE_URL}/cockpit`,"_self")}
              >
                   <Link> <span class="icon md lftarw-white c-pointer" ></span> Back To  SuisseBase Dashboard</Link>
              </Menu.Item>}
              {window.location.pathname.indexOf("/dashboard") < 0  && 
               <Menu.Item 
              onClick={() => this.routeToDashboard()}
              className="list-item header-send">
         
                <Link>Dashboard</Link>
              </Menu.Item>}
             {this.state?.sendPermissionName && (
              <Menu.Item 
              onClick={() => this.onMenuItemClick("Send", { key: "send", path: "/transfer" })}
              className="list-item header-send">
         
                <Link>Send</Link>
              </Menu.Item>)}
              </Menu>
            <Menu
              theme="light"
              mode="horizontal"
              className="header-right mobile-headerview"
              selectedKeys={[this.props.location.pathname]}
            >
              <Dropdown
                overlay={userProfileMenu}
                trigger={["click"]}
                placement="topRight"
                arrow
                overlayClassName="secureDropdown"
              >
                <Menu.Item key="3">
                 
                  {this.props.userConfig?.imageURL != null && (
                        <img
                            src={
                                this.props.userConfig?.imageURL
                                    ? this.props.userConfig?.imageURL
                                    : DefaultUser
                            }
                            className="user-profile"
                            alt={"image"}
                        />
                    )}
                    {this.props.userConfig?.imageURL === null && (
                        <img
                            src={
                                this.props.userConfig?.imageURL
                                    ? this.props.userConfig?.imageURL
                                    : DefaultUser
                            }
                            className="user-profile"
                            alt={"image"}
                        />
                    )}

                </Menu.Item>
              </Dropdown>
            </Menu>
          </div>
          <HeaderPermissionMenu/>
          </section>
          <Wraplogout isLogout={this.state.logoutuser} />
        </Layout>
      </>
    );
  }
}

const connectStateToProps = ({ userConfig,menuItems }) => {
  return {
     userConfig: userConfig.userProfileInfo ,
     sendPermissions: menuItems?.featurePermissions?.send,
  };
};
export default connect(connectStateToProps)(withRouter(AppHeader));

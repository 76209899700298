import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {Typography } from 'antd';
import AccountView from "./accountView";
import { setSelectedFeatureMenu } from "../../reducers/featuresReducer";
import { getFeaturePermissionsByKey } from "../../components/shared/permission/permissionService";
const {Text } = Typography;

class Dashboard extends Component {
  componentDidMount() {
    getFeaturePermissionsByKey('dashboard')
    getFeaturePermissionsByKey('send')
    getFeaturePermissionsByKey('receive')
    getFeaturePermissionsByKey(`transfer`);
    this.permissionsInterval = setInterval(this.loadPermissions, 200);
}
  loadPermissions = () => {
    if(this.props?.updateAccess){
      this.props.history.push("/accessdenied");
    }else{
      if (this.props.dashboardPermissions) {
        this.props.dispatch(setSelectedFeatureMenu(this.props.dashboardPermissions?.featureId));
        clearInterval(this.permissionsInterval);
        let _permissions = {};
        for (let action of this.props.dashboardPermissions?.actions) {
          _permissions[action.permissionName] = action.values;
        }
        this.setState({ ...this.state, permissions: _permissions });
        
        if (!_permissions.view) {
          this.props.history.push("/accessdenied");
        }
      }
      if(this.props.sendPermissions){
        this.props.dispatch(setSelectedFeatureMenu(this.props.sendPermissions?.featureId));
        clearInterval(this.permissionsInterval);
        let _permissions = {};
        for (let action of this.props.sendPermissions?.actions) {
          _permissions[action.permissionName] = action.values;
        }
        this.setState({ ...this.state, sendPermissionName: _permissions });
      }
      if(this.props.receivePermissions){
        this.props.dispatch(setSelectedFeatureMenu(this.props.receivePermissions?.featureId));
        clearInterval(this.permissionsInterval);
        let _permissions = {};
        for (let action of this.props.receivePermissions?.actions) {
          _permissions[action.permissionName] = action.values;
        }
        this.setState({ ...this.state, receivePermissionName: _permissions });
      }
      if (this.props.digitalWalletPermissions) {
        let _permissions = {};
        for (let action of this.props.digitalWalletPermissions?.actions) {
          _permissions[action.permissionName] = action.values;
        }
        this.setState({ ...this.state, digitalPermissionName: _permissions });
      }
    }
	}
  
  render() {
    
    return (
      <>
       <div className='d-flex align-center'>
                <Text
                    content="Dashboard"
                    className="db-main-title"
                  >
                   Bank Dashboard
                  </Text>
                <span className='acount-type'>{this.props.userProfile?.isBusiness ? "Business":"Personal"}</span>
            </div>
        <AccountView
          action={this.props.match.params?.action}
          currency={this.props.match.params?.currency}
          id={this.props.match.params?.id}
          sendPermissionName={this.state?.sendPermissionName?.View}
          receivedPermissionName={this.state?.receivePermissionName?.View}
          digitalPermissionName={this.state?.digitalPermissionName?.Transfer}
          dashboardPermissions={this.state?.permissions}
        />
      </>
    );
  }
}

const connectStateToProps = ({  userConfig, menuItems, auth }) => {
  return {
    userProfile: userConfig?.userProfileInfo,
    dashboardPermissions: menuItems?.featurePermissions?.dashboard,
    sendPermissions: menuItems?.featurePermissions?.send,
    receivePermissions: menuItems?.featurePermissions?.receive,
    digitalWalletPermissions: menuItems?.featurePermissions?.transfer,
    auth: auth?.user?.profile,
    updateAccess: menuItems?.accessDenied
    
  };
};
const connectDispatchToProps = (dispatch) => {
  return {
    dispatch
  };
};


export default connect(
  connectStateToProps,
  connectDispatchToProps
)(withRouter(Dashboard));

import {bankClient} from "../../api/clients"
import{ApiControllers} from '../../api/config';
const createConfirmationSlip=(obj)=>{
return bankClient.post(ApiControllers.bank+`CreateConfirmationSlip`,obj);
}
const saveDigitalWallet = (obj) =>{
    return bankClient.post(ApiControllers.bank +'TransferDigitalWallet',obj)
}
const downloadAttachment = (transactionId) =>{
    return bankClient.get(ApiControllers.bank+`ConfirmationSlip/${transactionId}`)
}
const getVerificationFields = () => {
	return bankClient.get(
		ApiControllers.addressbook + `Verificationfields`
	);
};
const getCustomerBankDetails = ()=>{
	return bankClient.get(ApiControllers.bank + `AccountDetailsForBank`);
  }
  const getAccountBalance = (obj)=>{
	return bankClient.post(ApiControllers.bank + `Account/AccountBalance`,obj);
  }
export{createConfirmationSlip,saveDigitalWallet,downloadAttachment,getVerificationFields,getCustomerBankDetails,getAccountBalance}
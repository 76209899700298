import { useEffect } from 'react'
import AppContent from './content';
import { Layout as AntLayout } from 'antd';
import AppFooter from './footer';
import AppHeader from './header';
import OnBoarding from './onboard.component';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { profileSuccess, setToken } from '../reducers/authReducer';
import NotKyc from '../components/shared/notKyc';
const AppLayout = (props) => {
  const { isAuthenticated, loginWithRedirect, user, getAccessTokenSilently,error,isLoading } = useAuth0();

  const callApi = async () => {
    try {
      const token = await getAccessTokenSilently();
      props.acctoken(token)
    } catch (error) {
    }
  };
  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect();
    } else {
      props.updateProfile(user)
      callApi()
    }
  }, [])

  const redirect = () => {
    window.open(process.env.REACT_APP_ADMIN_URL, "_self")
  }

  if (!isAuthenticated || !props.auth.profile || !props.auth.deviceToken) {
    return <div className="loader">Loading .....</div>
  } else if (props.auth.profile && !props.userProfile) {
    return <OnBoarding />
  } else if (props.userProfile && props.userProfile?.role === 'Admin') {
    return redirect();
  } else if (props.userProfile && !props.userProfile?.isKYC) {
    return <><NotKyc /></>
  }else if (props.twoFA?.loading) {
    return <div className="loader">Loading .....</div>
}
  if (error) {
    return <div>Oops... {error.message}</div>;
}
if (isLoading) {
    return <div className="loader">Loading .....</div>
}
  return (
    <>
      <AntLayout>
        <AppHeader />
        <AppContent />
        <AppFooter />
      </AntLayout>

    </>
  )

}
const connectStateToProps = ({ auth, userConfig }) => {
  return { auth, userProfile: userConfig.userProfileInfo }

}
const connectDispatchToProps = dispatch => {
  return {
    updateProfile: (info) => { dispatch(profileSuccess(info)) },
    acctoken: (data) => { dispatch(setToken(data)) },
    dispatch
  }

}

export default connect(connectStateToProps, connectDispatchToProps)((withRouter(AppLayout)));
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';
import '../src/assets/css/framework.css';
import '../src/assets/css/styles.css';
import '../src/assets/css/custom-styles.css';
import '../src/assets/css/dark-theme.css';
import '@progress/kendo-theme-default/dist/all.css';
import IdleCmp from './auth/idle.component';
import { CookiesProvider } from 'react-cookie';
import { getConfig } from './auth/auth0/config';
import { Auth0Provider } from '@auth0/auth0-react';
import history from './auth/auth0/history';

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  authorizationParams:{
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    ...(config.audience ? { audience: config.audience } : null)
  },
  onRedirectCallback,
};

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider  {...providerConfig}>
    <CookiesProvider>
    {/* <App /> */}
    <IdleCmp />
    </CookiesProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

import React, {  useEffect, useState } from 'react';
import Tooltip from 'antd/lib/tooltip';
import Col from "antd/lib/col";
import Typography from "antd/lib/typography";
import Modal from 'antd/lib/modal';
import Button from "antd/lib/button";
import  message  from "antd/lib/message";
import Alert from "antd/lib/alert";

import Loader from "../shared/loader";
import {previewDataDownload,isErrorDispaly} from '../../api/apiCalls'
import NumberFormat from 'react-number-format';

const  TransactionSlips =(props)=> {
  const [previewInfo, setPreviewInfo] = useState([]);
  const [downloadLoader,setDownloadLoader] = useState(false)
  const [error, setError] = useState(null);
  const [showModal,setShowModal]=useState(false)
  const handleModalCancel = () => {
    setShowModal(false)
  }

  useEffect(()=>{
    setShowModal(props.showModal)

    let previewInfo = [
      {
          name:'Transaction Type',
          displayType:'string',
          value:props?.viewData?.docType,
      },
      {
          name:'Date',
          displayType:'dateTime',
          value:props?.viewData?.date,
      },
      {
          name:'Amount',
          displayType:'amount',
          extraAddon:props?.viewData?.currency,
          value:props?.viewData?.amount === null?'--': props?.viewData?.amount ,
      },

      {
          name:'SB Fee',
          displayType:'amount',
          value:props?.viewData?.sbFee === null?'--':props?.viewData?.sbFee,
      },
    ];
      if( props.viewData?.docType == "Withdrawal"){
      previewInfo = [...previewInfo,
      {
              name:'Receiver Name',
              displayType:'string',
              value:props?.viewData?.receiversName === null?'--':props?.viewData?.receiversName,
      },
      {
              name:'Receiver Bank Name',
              displayType:'string',
              value:props?.viewData?.receiversBankName === null?'--':props?.viewData?.receiversBankName,
      },
      {
              name:'Receiver Bank Account',
              displayType:'string',
              value:props?.viewData?.receiversBankAccountNo === null?'--':props?.viewData?.receiversBankAccountNo,
      }]
    }
      else if(props.viewData?.docType == "Deposit"){
      previewInfo = [...previewInfo,
      {
            name:'Sender Name',
            displayType:'string',
            value:props?.viewData?.senderName === null?'--':props?.viewData?.senderName,
      },
      {
            name:'Sender Bank Account',
            displayType:'string',
            value:props?.viewData?.sendersBankAccount === null?'--':props?.viewData?.sendersBankAccount,
      },
      ]
    }
    previewInfo = [...previewInfo,
    {
        name:'Status',
        displayType:'string',
        value:props?.viewData?.status,
    }
    ]

    setPreviewInfo(previewInfo);
  },[props.viewData])

  const { Title } = Typography;

    const getDownloadData =async()=>{
    setDownloadLoader(true)

      let response = await previewDataDownload(props?.id,props?.viewData.docType)
      if(response.ok){
      setError(null)
      setDownloadLoader(false)
      setShowModal(false)
      const pdfUrl = response.data;
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.target = '_blank';
      link.download = 'file.pdf';
      link.click();
      message.success({ content: "Downloaded successfully", className: 'custom-msg', duration: 3 });
      }else{
      setDownloadLoader(false)
      setShowModal(false)
      setError(isErrorDispaly(response));

    }
  }

  return (
    <>
      {error != undefined && error != null && (
        <Alert
          className="w-100 mb-16"
          type="error"
          showIcon
          description={error}
        />
      )}
      <Modal
        title="Transaction Details" visible={showModal}
        closeIcon={
          <Tooltip title="Close">
            <span
              className="icon md close-white c-pointer"
              onClick={() => handleModalCancel()}
            />
          </Tooltip>

        }
        footer={[
          <>{!props.load && <>
          {(props.viewData?.status==="Approved" || props.viewData?.status==="Approved (Sent)") &&
              <Button block className="primary-btn pop-btn ml-0"
                loading={downloadLoader}
                onClick={getDownloadData}
              > Download </Button>}
              <Button block 
                className=" cust-cancel-btn transaction-cancelbtn"
              onClick={() => handleModalCancel()}
            >Cancel</Button>
               
          </>}
          </>
        ]} >
        <>
          {props.load && <Loader/>}
             {!props.load && previewInfo.map((item=>{if(item.displayType){return <>            
                <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                <div className="pay-list modal-listbr" style={{ alignItems: 'baseline' }} key={item.id}>
                <Title className="summary-liststyle">{item.name}</Title>
                <Title className="summarybal">
                 {item.displayType ==='string' && <>{item?.value}</>}
                      {item.displayType === 'dateTime' && <>{item?.value}</>}
                 {item.displayType === 'amount'  && <><NumberFormat value={item?.value} className="text-white-30 fw-600" displayType={'text'} thousandSeparator={true} 
                 suffix={item.extraAddon ? `${" "}${item.extraAddon}`: " "}
                      /></>}
                    </Title>
                  </div>
                </Col>
              </>}}))}              
        </>

      </Modal>
    </>
  );

}

export default TransactionSlips
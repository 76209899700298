import React, { useEffect, useState } from "react";

import Drawer from "antd/lib/drawer";
import Typography from "antd/lib/typography";
import Alert from "antd/lib/alert";
import  Spin  from "antd/lib/spin";

import { connect } from "react-redux";
import PastRecipientDetails from "./summaryDetails";
import { sendSteps as config } from './config';
import { setDigitalWalletStep } from "../../reducers/digitalWalletReducer";
import TransferAmount from './transferAmount';
import ConfirmationSlip from './confirmation'
import {getCustomerBankDetails} from './api'
import { getFeaturePermissionsByKey } from "../../components/shared/permission/permissionService";
import { isErrorDispaly } from "../../api/apiCalls";

const SuisseBaseDigitalWallet = ({ digitalWalletData, onDigitalWalletClose, dispatch,userConfig,setStep, currency,...props}) => {
  const { Paragraph } = Typography
  const useDivRef = React.useRef(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const[transactionId,setTransactionId] = useState(null);
  const[currencyObj,setCurrencyObj] = useState();
  const[amount,setAmountValue] = useState();
  const [permission,setPermission]=useState({});

  useEffect(() => {
    getFeaturePermissionsByKey(`transfer`);
    loadPermissions()
    setErrorMsg(null);
    setShowDrawer(true);
    if(currency){
    getCustomerAccountBalance()  
    }
  },[currency])
  const loadPermissions = () => {
		if (props.digitalWalletPermissions) {
			let _permissions = {};
			for (let action of props.digitalWalletPermissions?.actions) {
				_permissions[action.permissionName] = action.values;
			}
            setPermission(_permissions)
		}
	}
  const  getCustomerAccountBalance = async () => {
    setLoader(true);
         let response = await getCustomerBankDetails();
         if (response.ok) {
           let obj = response?.data?.filter((item) =>{
             return item.currency === currency
           })
           setErrorMsg(null);
           setCurrencyObj(obj[0]);
        setLoader(false);
         } else {
          setErrorMsg(isErrorDispaly(response))
          setLoader(false);
         }
       };
       
  const renderTitle = () => {
    const titles = {
      transferAmount: <span />,
      sendSummary: <span onClick={() => dispatch(setDigitalWalletStep("transferAmount"))} className="" />,
      confimationSlip: <span />,
    }
    return titles[config[digitalWalletData?.stepcode]]
  }
  const setLoaderState = () => {
    setLoader(true);
  }
  const getTransactionId = (id) => {
    setTransactionId(id);
  }
  const setAmount = (value) =>{
    setAmountValue(value);
  }
  const renderContent = () => {
    const stepcodes = {
      transferAmount: <TransferAmount reciveCurrency={currency} currencyObj={currencyObj} {...props} setAmount = {(value) =>setAmount(value)} amount={amount} permission={permission}/>,
      sendSummary: <PastRecipientDetails sendLoading={() => setLoaderState()} sendTransactionId = {(id)=> getTransactionId(id)} amount={amount} currencyObj={currencyObj}/>,
      confimationSlip: <ConfirmationSlip sendLoading={setLoaderState} transactionId={transactionId} currencyObj={currencyObj}  amount={amount}  closeDrawer={() => closeSendDrawer()}
      />,
    }
    return stepcodes[config[digitalWalletData?.stepcode]]
  }
  const renderIcon = () => {
    const stepcodes = {
      transferAmount: <span onClick={closeSendDrawer} className="icon md close-white c-pointer" />,
      confimationSlip: <span onClick={closeSendDrawer} className="icon md close-white c-pointer" />,
      sendSummary: <span onClick={() => closeSendDrawer()} className="icon md close-white c-pointer" />,
      sendSuccess: <span onClick={closeSendDrawer} className="icon md close-white c-pointer" />,
    }
    return stepcodes[config[digitalWalletData.stepcode]]
  }
  const closeSendDrawer = () => {
    let _path = props?.location?.pathname?.split("/");
    if (_path && _path[2] === 'digitalwallet') {
      props?.history?.push('/dashboard');
    }
 else{
    setShowDrawer(false);
    onDigitalWalletClose()
 }
 setErrorMsg(null);
 setAmountValue("");
 if(digitalWalletData?.stepcode === 'confimationSlip'){
 props?.refreshDashBoard(true);
 }
 dispatch((setDigitalWalletStep("transferAmount")))
  }
  return (
    <>
      <Drawer
        destroyOnClose={true}
        title={[<div className="side-drawer-header"> 
         {errorMsg && <Alert
                    description={errorMsg}
                    className="w-100 mb-16"
                    type="error"
                    showIcon
                />}
          {renderIcon()}
        </div>]}
        className="side-drawer w-50p"
        visible={showDrawer}
      >
        <Spin spinning={loader} >
        {renderTitle()}
          <div className="mb-16">
          <div ref={useDivRef}></div>
               
            <div className="drawer-maintitle text-center">{digitalWalletData?.stepTitles[config[digitalWalletData.stepcode]]}</div>
          </div>
          {renderContent()}
        </Spin>
      </Drawer>
    </>
  );
}
const connectStateToProps = ({ digitalWalletData,userConfig ,menuItems}) => {
  return {
    digitalWalletData,
    userConfig: userConfig.userProfileInfo,
    digitalWalletPermissions: menuItems?.featurePermissions?.transfer,
  }
}
const connectDispatchToProps = dispatch => {
  return {
      changeStep: (stepcode) => {
          dispatch(setDigitalWalletStep(stepcode))
      },
    dispatch
  }
}
export default connect(connectStateToProps, connectDispatchToProps)(SuisseBaseDigitalWallet);

import React, { Component } from "react";
import { Row, Col, Form, Input, Typography} from 'antd';
import { validateContentRule,validateFeild } from "../../../utils/custom.validator";

const {  Text } = Typography;
const { TextArea } = Input;
class BankDetails extends Component {
    state = {
        emailExist: false,
        bankDetailForm: React.createRef(),
        countries: [],
        states: [],
        isLoading: false
    }
   
    renderAddress = (transferType) => {
        const _templates = {
            sepa: <>
            <>
                <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                        className="custom-forminput custom-label mb-0"
                        name="IBAN"
                        label={"IBAN"}
                        required
                        rules={[
                            {
                                required: true,
                                message:
                                    "Is required",
                            },
                            {
                                validator(_, value) {
                                    if (this.state.emailExist) {
                                        return Promise.reject(
                                            "Invalid  IBAN Number"
                                        );
                                    } else if (
                                        value &&
                                        !/^[A-Za-z0-9]+$/.test(value)
                                    ) {
                                        return Promise.reject(
                                            "Invalid  IBAN Number"
                                        );
                                    } 
                                    else if ( value && value.length > 50) {
                                        return Promise.reject("Invalid IBAN number");
                                    } else {
                                        return validateContentRule(_, value);
                                    }
                                },
                            },
                        ]}
                        onBlur={(e) => {
                            //handleIban(e.target.value)
                        }}
                    >
                        <Input
                            className="cust-input"
                            placeholder={"IBAN"}
                            maxLength={50}
                        />
                    </Form.Item>
                </Col>
                 
                <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                        className="custom-forminput custom-label mb-0"
                        name="line2"
                        required
                        rules={[
                            {
                                validator:(_,val)=>{
                                    return validateFeild({val:val,required: true,maxLength:50,validateContentRule:true,lableName:'Reason for Transfer'})
                                }
                            },
                            {
                                whitespace: true,
                                message: "Is required",
                            },
                        ]}
                        label={
                            "Reason for Transfer"
                        }
                    >
                        <Input
                                className="cust-input"
                                placeholder={"Reason for Transfer"}
                                // maxLength="500"
                            />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                        className="custom-forminput custom-label mb-0"
                        name="line2"
                        required
                        rules={[
                            {
                                validator:(_,val)=>{
                                    return validateFeild({val:val,required: true,maxLength:50,validateContentRule:true,lableName:'relationship to beneficiary'})
                                }
                            },
                            {
                                whitespace: true,
                                message: "Is required",
                            },
                        ]}
                        label={
                            "Relationship To Beneficiary"
                        }
                    >
                        <Input
                                className="cust-input"
                                placeholder={"Relationship To Beneficiary"}
                                // maxLength="500"
                            />
                    </Form.Item>
                </Col>
                </>
                <div className="box basic-info alert-info-custom mt-16">
                <Row>
                    <Col xs={24} md={8} lg={24} xl={8} xxl={8} className="mb-16">
                        <label className="fs-14 fw-400 text-white">
                            <strong>Bank Name</strong>
                        </label>
                        <div><Text className="fs-14 fw-400 text-white">Barcslays Bank UK PLC</Text></div>

                    </Col>
                    <Col xs={24} md={8} lg={24} xl={8} xxl={8} className="mb-16">
                        <label className="fs-14 fw-400 text-white">
                            <strong>BIC</strong>
                        </label>
                        <div><Text className="fs-14 fw-400 text-white">BUKBGB22</Text></div>

                    </Col>
                    <Col xs={24} md={8} lg={24} xl={8} xxl={8} className="mb-16">
                        <label className="fs-14 fw-400 text-white">
                            <strong>Branch</strong>
                        </label>
                        <div><Text className="fs-14 fw-400 text-white">CHELTENHAM</Text></div>

                    </Col>
                    <Col xs={24} md={8} lg={24} xl={8} xxl={8} className="mb-16">
                        <label className="fs-14 fw-400 text-white">
                            <strong>Branch</strong>
                        </label>
                        <div><Text className="fs-14 fw-400 text-white">CHELTENHAM</Text></div>

                    </Col>
                    <Col xs={24} md={8} lg={24} xl={8} xxl={8} className="mb-16">
                        <label className="fs-14 fw-400 text-white">
                            <strong>Country</strong>
                        </label>
                        <div><Text className="fs-14 fw-400 text-white">United Kingdom (GB)</Text></div>

                    </Col>
                    <Col xs={24} md={8} lg={24} xl={8} xxl={8} className="mb-16">
                        <label className="fs-14 fw-400 text-white">
                            <strong>State</strong>
                        </label>
                        <div><Text className="fs-14 fw-400 text-white">XXXX</Text></div>

                    </Col>
                    <Col xs={24} md={8} lg={24} xl={8} xxl={8} className="mb-16">
                        <label className="fs-14 fw-400 text-white">
                            <strong>City</strong>
                        </label>
                        <div><Text className="fs-14 fw-400 text-white">Leicester</Text></div>

                    </Col>
                    <Col xs={24} md={8} lg={24} xl={8} xxl={8} className="mb-16">
                        <label className="fs-14 fw-400 text-white">
                            <strong>Zip</strong>
                        </label>
                        <div><Text className="fs-14 fw-400 text-white">LE87 2BB</Text></div>

                    </Col>
                </Row>
            </div>
            </>,
            swift: <>
                <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                        className="custom-forminput custom-label mb-0"
                        name="accountNumber"
                        label={"Account Number"}
                        required
                        rules={[
                            {
                                required: true,
                                message:
                                    "Is required",
                            },
                            {
                                validator(_, value) {
                                    if (this.state.emailExist) {
                                        return Promise.reject(
                                            "Invalid  Bank Account Number"
                                        );
                                    } else if (
                                        value &&
                                        !/^[A-Za-z0-9]+$/.test(value)
                                    ) {
                                        return Promise.reject(
                                            "Invalid  Bank Account Number"
                                        );
                                    } 
                                    else if ( value && value.length > 50) {
                                        return Promise.reject("Invalid bank account number");
                                    } else {
                                        return validateContentRule(_, value);
                                    }
                                },
                            },
                        ]}
                    >
                        <Input
                            className="cust-input"
                            placeholder={"Account Number"}
                            maxLength={50}
                        />
                    </Form.Item>
                </Col>
                {this.props.domesticType === "international" &&<Col xs={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                        className="custom-forminput custom-label mb-0"
                        name="swiftCode"
                        label={"Swift/BIC Code"}
                        required
                        rules={[
                            {
                                required: true,
                                message:
                                    "Is required",
                            },
                            {
                                validator(_, value) {
                                    if (this.state.emailExist) {
                                        return Promise.reject(
                                            "Invalid BIC/SWIFT/Routing number"
                                        );
                                    } else if (
                                        value &&
                                        !/^[A-Za-z0-9]+$/.test(value)
                                    ) {
                                        return Promise.reject(
                                            "Invalid BIC/SWIFT/Routing number"
                                        );
                                    }   else if ( value && value.length > 50) {
                                        return Promise.reject("Invalid BIC/SWIFT/Routing number");
                                    } 
                                    else {
                                        return validateContentRule(_, value);
                                    }
                                },
                            },
                        ]}
                    >
                        <Input
                            className="cust-input "
                            placeholder={"Swift/BIC Code"}
                        />
                    </Form.Item>
                </Col>}

                {this.props.domesticType === "domestic" && <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                        className="custom-forminput custom-label mb-0"
                        name="routingNumber"
                        label={"Routing Number"}
                        required
                        rules={[
                            {
                                required: true,
                                message:
                                    "Is required",
                            },
                            {
                                validator(_, value) {
                                    if (this.state.emailExist || (value && !/^[A-Za-z0-9]+$/.test(value)) || value.length > 50) {
                                      return Promise.reject("Invalid ACH/Routing number");
                                    } else {
                                      return validateContentRule(_, value);
                                    }
                                  }
                            },
                        ]}
                    >
                        <Input
                            className="cust-input "
                            placeholder={"Routing Number"}
                        />
                    </Form.Item>
                </Col>}
                <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                        className="custom-forminput custom-label mb-0"
                        name="bankName"
                        label={"Bank Name"}
                        required
                        rules={[
                            {
                                validator:(_,val)=>{
                                    return validateFeild({val:val,required: true,maxLength:50,validateContentRule:true,lableName:'bank name'})
                                }
                            },
                            {
                                whitespace: true,
                                message:
                                    "Is required",
                            },
                        ]}
                    >
                        <Input
                            className="cust-input"
                            placeholder={"Bank Name"}
                            maxLength={100}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                        className="custom-forminput custom-label mb-0"
                        name="line1"
                        required
                        rules={[
                            {
                                validator:(_,val)=>{
                                    return validateFeild({val:val,required: true,maxLength:250,validateContentRule:true,lableName:'Bank address line 1'})
                                }
                            },
                            {
                                whitespace: true,
                                message: "Is required",
                            },
                        ]}
                        label={
                            "Bank Address 1"
                        }
                    >
                        <TextArea
                            placeholder={"Bank Address 1"}
                            className="cust-input cust-text-area address-book-cust"
                            autoSize={{ minRows: 1, maxRows: 2 }}
                            maxLength={1000}
                        ></TextArea>
                    </Form.Item>
                </Col>
                <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                    <Form.Item
                        className="custom-forminput custom-label mb-0"
                        name="line2"
                        required
                        rules={[
                            {
                                validator:(_,val)=>{
                                    return validateFeild({val:val,required: false,maxLength:250,validateContentRule:true,lableName:'bank address line 2'})
                                }
                            },
                            {
                                whitespace: true,
                                message: "Is required",
                            },
                        ]}
                        label={
                            "Bank Address 2"
                        }
                    >
                        <TextArea
                            placeholder={"Bank Address 2"}
                            className="cust-input cust-text-area address-book-cust"
                            autoSize={{ minRows: 1, maxRows: 2 }}
                        ></TextArea>
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                        className="custom-forminput custom-label mb-0"
                        name="line2"
                        required
                        rules={[
                            {
                                validator:(_,val)=>{
                                    return validateFeild({val:val,required: true,maxLength:50,validateContentRule:true,lableName:'Reason for Transfer'})
                                }
                            },
                            {
                                whitespace: true,
                                message: "Is required",
                            },
                        ]}
                        label={
                            "Reason for Transfer"
                        }
                    >
                        <Input
                            className="cust-input"
                            placeholder={"Reason for Transfer"}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                        className="custom-forminput custom-label mb-0"
                        name="line2"
                        required
                        rules={[
                            {
                                validator:(_,val)=>{
                                    return validateFeild({val:val,required: true,maxLength:50,validateContentRule:true,lableName:'relationship to beneficiary'})
                                }
                            },
                            {
                                whitespace: true,
                                message: "Is required",
                            },
                        ]}
                        label={
                            "Relationship To Beneficiary"
                        }
                    >
                        <Input
                            className="cust-input"
                            placeholder={"Reason for Transfer"}
                        />
                    </Form.Item>
                </Col>
            </>
        }
        return _templates[transferType]
    }
    render() {
        const {transferType, } = this.props;
        
        return <>
           
            <Row gutter={[16, 16]} className={'pb-16'}>
                {this.renderAddress(transferType)}
            </Row>
            </>

    }
}
export default BankDetails;
import React, { Component } from "react";

 import Typography from "antd/lib/typography";
 import Modal from "antd/lib/modal"; 
 import Tooltip from "antd/lib/tooltip";
 import  Row  from "antd/lib/row";
 import Col from "antd/lib/col";
 import  Alert  from "antd/lib/alert";
import { connect } from "react-redux";
import { setDigitalWalletStep, setAmountValue } from "../../reducers/digitalWalletReducer";
import Confirmation from "../shared/confirmation";
import inprogress from '../../assets/images/inprogress.png'
import { downloadAttachment } from './api'
import Loader from "../shared/loader";
import { isErrorDispaly } from "../../api/apiCalls";
const { Text } = Typography;

class ConfirmationSlip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMsg: null,
      status: true,
      loader: false,
      state: "In Progress",
      downloadPopup: false,
      fileData: "",
      isDownLoadShow:false,
      amountText: "Recipient gets",
      transferDetails: [
        {
          name: 'Transfer details',
          details: [
            {
              key: 'Recipient gets',
              amount: parseFloat(props?.amount), //-props?.amount * 1/100
              currency: this.props?.currencyObj?.currency
            }
          ]
        },
      ],
    };
  }
  formRef = React.createRef();
  componentDidMount() {
   
  }
  downLoad = async () => {
    this.setState({ ...this.state, downloadPopup: true });
    this.downLoadPDF();
  }

  downLoadPDF = async () => {
    let downloadObj = Object.assign({});
    downloadObj.receipentGets =`${this.props?.amount}`;
    downloadObj.totalFees  =  `0`;
    downloadObj.withdrawalAmount = null;
    downloadObj.description = "Suissebase digital wallet";
    downloadObj.name = this.props?.userConfig?.isBusiness ? this.props?.userConfig?.businessName : this.props?.userConfig?.firstName  + " "+ this.props?.userConfig?.lastName 
    downloadObj.firstName = this.props?.userConfig?.firstName 
    downloadObj.lastName = this.props?.userConfig?.lastName 
    downloadObj.accountNumber = ""
    downloadObj.bankName = ""
    downloadObj.currency = this.props?.currencyObj?.currency
    downloadObj.email = this.props?.userConfig?.email
    downloadObj.bankCode = ""
    downloadObj.receipentEmail = "xxxx"
    downloadObj.customerId = this.props?.currencyObj?.customerId
    this.setState({ loader: true });
    var response = await downloadAttachment(this.props?.transactionId)
    if (response.ok) {
      this.setState({ ...this.state, fileData: response.data, loader: false,isDownLoadShow:true});
    }
    else {
      this.setState({ ...this.state, errorMsg: isErrorDispaly(response), loader: false });
    }
  }

  handleClose = () => {
    this.setState({ ...this.state, downloadPopup: false });
  }
  render() {
    const { errorMsg } = this.state;
    return (
      <>

        <Confirmation
          transferDetails={this.state.transferDetails}
          status={this.state.status}
          state={this.state.state}
          isButtonShow={true}
          imageIcon={inprogress}
          confirmaionSlip={() => this.downLoad()}
          closeDrawer={()=>this.props.closeDrawer()}
        />
        <Modal
          title="Download Confirmation Slip"
          visible={this.state.downloadPopup}
          closeIcon={
            <Tooltip title="Close">
              <span
                className="icon md close-white c-pointer"
                onClick={() => this.handleClose()}
              />
            </Tooltip>
          }
          footer={null} >
          {errorMsg && (
            <Alert type="error" showIcon description={errorMsg} />
          )}
          {this?.state.loader ? <Loader /> :
            <Row gutter={24}>
            { this.state.isDownLoadShow && <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                <div className="d-flex justify-content align-center" >
                  <Text className="mb-8 fs-14 text-white fw-500">Download as PDF </Text>
                  <a className="icon md download-arrow text-right c-pointer" download="Confirmation slip" href={"data:application/pdf;base64," + this.state.fileData} title='Download pdf document' />
                </div>
              </Col>}
            </Row>}
        </Modal>
      </>
    );
  }
}
const connectStateToProps = ({ userConfig, digitalWalletData }) => {
  return {
    userConfig: userConfig.userProfileInfo, digitalWalletData

  };
};
const connectDispatchToProps = dispatch => {
  return {
    changeStep: (stepcode) => {
      dispatch(setDigitalWalletStep(stepcode))
    },
    setAmount: (amount) => {
      dispatch(setAmountValue(amount))
    },
    dispatch
  }

}
export default connect(connectStateToProps, connectDispatchToProps)(ConfirmationSlip)

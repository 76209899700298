import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { profileSuccess } from "../reducers/authReducer";
import { withCookies} from 'react-cookie';
const CallbackPage =(props)=> {
    
useEffect(()=>{
    handleRedirect();
},[])

 const handleRedirect = () => {
        props.history.push("/dashboard");
        localStorage.removeItem("__url");
    }
 
        return (<>
                <div className="loader">Loading .....</div>
            </>  )
        
    }

const mapStateToProps = ({ oidc }) => {
    return { oidc }
}
const mapDispatchToProps = dispatch => {
    return { updateProfile: (info) => { dispatch(profileSuccess(info)) } }
}
export default connect(mapStateToProps, mapDispatchToProps)(withCookies(withRouter(CallbackPage)));
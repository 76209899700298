const SET_RECEIVE_STEP = "setReceiveStep";
const CLEAR_STEP = "clearStep";
const CHANGE_STEP = "changeStep";
const SET_RECEIVE_OBJ = "setReceiveObj";

  const setReceiveStep = (payload) => {
    return {
        type: SET_RECEIVE_STEP,
        payload
    }
}
const changeStep = (payload) => {
    return {
        type: CHANGE_STEP,
        payload
    }
}

const clearStep = (payload) => {
  return {
      type: CLEAR_STEP,
      payload
  }
}
const setReceiveObj = (payload) => {
  return {
      type: SET_RECEIVE_OBJ,
      payload
  }
}
  let initialState={
    stepcode: "step1",
    stepTitles: {
      step1:"Receive Funds",
        receiveFunds : "Receive Funds",
    },
    stepSubTitles: {
        // receiveFunds :null,
       
    },
    receiveDetailsObj:{},

  }
  
  
  const receiveReducer = (state = initialState,action) =>{
    switch (action.type) {
      case SET_RECEIVE_STEP:
            return { ...state, stepcode: action.payload };
        case CHANGE_STEP:
            return { ...state, stepcode: action.payload };
        case CLEAR_STEP:
            return { ...state, stepcode: action.payload };
            case SET_RECEIVE_OBJ:
              return { ...state, receiveDetailsObj: action.payload };
    default:
        return state;
  }

    }
    export default receiveReducer;
    export {setReceiveStep, clearStep, changeStep,setReceiveObj}
